export function findAuthToken(address: string | undefined){
    if(address === undefined){
        return null
    }
    
    const allTokens = window.localStorage.getItem("authToken")

    if(allTokens === null){
        return null
    }
    
    const addressToken = JSON.parse(allTokens)[address]

    return addressToken as string
}
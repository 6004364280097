import axios from "axios"
import { UserTask } from "../../interface/interface"



export async function getUserInfo(token: string){
    const resp = await axios.get("https://blinkswap.xyz/api/v1/info", {
        headers:{
            'Authorization': `token ${token}`
        }
    })

    const userInfoObject = resp.data.data.referral_info
    const userTask: UserTask = {
        isRetweetDone: userInfoObject.retweet_done,
        isTwitterFollowed: userInfoObject.twitter_followed,
        isTwitterAuthorized: userInfoObject.twitter_authorized,
        isDiscordFollowed: userInfoObject.discord_followed,
        isDiscordAuthorize: userInfoObject.discord_authorized,
        isBlinker: userInfoObject.role_blinker,
        isReffcodeUsed: userInfoObject.referred_by,
        refCode: userInfoObject.user_ref_code,
        boost: userInfoObject.boost
    }

    return userTask
}
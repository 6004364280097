import { useEffect, useState } from "react"
import { Reserves, Token } from "../../interface/interface"
import { formatUnits, parseUnits } from "viem"
import { formatBalance } from "../../app/utils/format"

interface SwapInfoComponentProps{
    tokenIn: Token | undefined,
    tokenOut: Token | undefined,
    amountIn: string | undefined,
    amountOut: string | undefined,
    reserves: Reserves | undefined
    slippage: string | undefined,
    setSlippage(token: React.SetStateAction<string | undefined>): void
}


function SwapInfoComponent({reserves, tokenIn, tokenOut, amountIn, amountOut, slippage, setSlippage}: SwapInfoComponentProps){

    const[reverseRatio, setReverseRatio] = useState(false)
    const[priceImpact, setPriceImpact] = useState("-.--")

    function handleSlippageChange(event: React.ChangeEvent<HTMLInputElement>){
        const formatedValue = event.target.value.replace(/[^0-9.,]/g, '').replace(",", ".").replace( /^([^\.]*\.)|\./g, '$1' )
        if(Number(formatedValue) >= 0 && Number(formatedValue) <= 100){
            setSlippage(formatedValue)
        }
    }

    function handleOnBlur(event: React.ChangeEvent<HTMLInputElement>){
        if(!event.target.value){
            setSlippage("0.5")
        }

        if(Number(event.target.value) === 0){
            setSlippage("0")
        }
    }

    function getRatio(a?: string, b?: string){
        const res = Number(a) / Number(b)
        
        if(isNaN(res)){
            return "-"
        }
        else{
            return formatBalance(res.toString())
        }
    }

    function getPriceImpact(){
        const allTokens = reserves?.tokenInReserve! * reserves?.tokenOutReserve!
        const newInPool = reserves?.tokenInReserve! + parseUnits(amountIn!, Number(tokenIn?.decimals!))

        const newOutPool = allTokens / newInPool

        const amountOut = reserves?.tokenOutReserve! - newOutPool
        const estimatePriceImpact = Number(formatUnits(amountOut, Number(tokenOut?.decimals))) / Number(formatUnits(newOutPool, Number(tokenOut?.decimals))) * 100

        return estimatePriceImpact.toFixed(2)
    }

    useEffect(()=>{
        if(reserves && tokenIn && tokenOut && amountIn){
            setPriceImpact(getPriceImpact())
        }

    }, [amountIn, amountOut, tokenIn, tokenOut])

    return(
        <div className="text-xs text-white flex flex-col px-2 gap-2">
            {tokenIn && tokenOut?
                <div className="cursor-pointer flex gap-1" onClick={()=>{setReverseRatio(!reverseRatio)}}>
                    {`1 ${reverseRatio? tokenOut?.name : tokenIn?.name} = ${reverseRatio? getRatio(amountIn, amountOut) : getRatio(amountOut, amountIn)} ${reverseRatio? tokenIn?.name : tokenOut?.name}`}
                    <div className="text-grey">{`(~$${reverseRatio? Number(tokenOut?.price).toFixed(2) : Number(tokenIn?.price).toFixed(2)})`}</div>
                </div>
                :
                <div className="">
                    Select pair to get quote...
                </div>
            }
            <div className="flex flex-col gap-[2px]">
                <div className="flex justify-between text-grey items-center">
                    <div>
                        Slippage
                    </div>
                    <div className="flex gap-1 items-center">
                        <input type="text" className="w-8 py-1 bg-opacity-0 bg-black outline-none border border-black text-white placeholder:text-grey text-center focus:border focus:border-white"
                            value={slippage}
                            onChange={handleSlippageChange}
                            onBlur={handleOnBlur}
                        />
                        <div>
                            %
                        </div>
                    </div>
                </div>
                <div className="flex justify-between text-grey">
                    <div>
                        Price Impact
                    </div>
                    <div>
                        {`${priceImpact} %`}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwapInfoComponent
import { useContext } from "react";
import { AppContext } from "../../app/app.context";
import blinkLogo from "../../assets/img/blonk-logo.svg"
import HeaderMenuComponent from "./HeaderMenuComponent";
import { Link } from "react-router-dom";
import { ConnectRainbowButton } from "../../shared/ConnectRainbowButton";

// const navbar = ["Features", "Blink Points", "Leaderboards"]

const navbar = [
    {
        name: "Swap",
        ref: "/swap"
    },
    {
        name: "Pool",
        ref: "/pool"
    },
    {
        name: "Blink Points",
        ref: "/quests"
    },
    {
        name: "Leaderboards",
        ref: "/leaderboard"
    }
]

function HeaderComponent() {
    const context = useContext(AppContext)

    if(context.width < 840){
        return (
            <HeaderMenuComponent/>
        );
    }

    return (
      <div className="w-full flex items-center justify-between">
        <div className="flex gap-2 2xl:gap-4 items-center z-30">
            <Link to="/">
                <img src={blinkLogo} alt="" className="size-11 2xl:size-12 cursor-pointer"/>
            </Link>
            <div className="font-mono font-light text-white text-xl 2xl:text-3xl">Blink</div>
        </div>
        <div className="flex gap-20">
            <div className="flex gap-8 2xl:gap-12 text-grey items-center z-30 w-full">
                {navbar.map((menuName)=>{
                    return(
                        <Link to={menuName.ref}>
                            <div className="cursor-pointer font-variable hover:text-white duration-150 text-sm whitespace-nowrap tracking-wider">
                                {menuName.name}
                            </div>
                        </Link>
                    )
                })}
            </div>
            {/* <DefaultButton className="text-xs whitespace-nowrap px-5 py-2 2xl:text-base font-semibold z-30 normal-case">
                Connect Wallet
            </DefaultButton> */}
            <div className="z-30 w-full">
                <ConnectRainbowButton/>
            </div>
        </div>
      </div>
    );
}

export default HeaderComponent;
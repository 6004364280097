export const BLAST_SCAN_URL = "https://blastscan.io/"
export const BLAST_RPC = "https://blast-sepolia.drpc.org"

export const routerAddress = "0xe38BdAa37742096DdE50F121863A63685C2Fc9C1"
export const factoryAddress = "0xFfbDb302f29B29ee45D650DF44889450d252d868"
export const poolsHelperAddress = "0x6648cDABb610Aa5764B2529fA582c3d8a4E436A9"

export const initialTokens = [
    {
        name: "ETH",
        address: "0x4300000000000000000000000000000000000004",
        icon: "ETH",
        isNative: true
    },
    {
        name: "WETH",
        icon: "WETH",
        address: "0x4300000000000000000000000000000000000004"
    },
    {
        name: "USDB",
        icon: "USDB",
        address: "0x4300000000000000000000000000000000000003"
    },
    {
        name: "WBTC",
        icon: "WBTC",
        address: "0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692"
    }
]

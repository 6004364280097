import { useContext, useEffect, useState } from "react";
import DefaultButton from "../../../shared/DefaultButton"
import ScobedText from "../../../shared/ScobedText"
import VerificationInput from "react-verification-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authTwitter, getTwitterAuthLink, twitterFollow, twitterRetweet } from "../../../app/api/twitterApi";
import { getDiscordAuthLink, sendDiscordCode, verifyRole } from "../../../app/api/discordApi";
import { getReferralCode, submitReferralCode } from "../../../app/api/referralApi";
import { getUserInfo } from "../../../app/api/infoApi";
import { UserTask } from "../../../interface/interface";
import DisableButton from "../../../shared/DisableButton";
import { AppContext } from "../../../app/app.context";
import { ToastContainer, toast } from "react-toastify";

const defaultTask: UserTask = {
    isRetweetDone: false,
    isTwitterFollowed: false,
    isTwitterAuthorized: false,
    isDiscordFollowed: false,
    isDiscordAuthorize: false,
    isBlinker: false,
    isReffcodeUsed: false,
    boost: ""
}

function SocialQuestTasksComponent(){
    const[userTask, setUserTask] = useState(defaultTask)

    const[twitterAuthLink, setTwitterAuthLink] = useState("")
    const[discordAuthLink, setDiscordAuthLink] = useState<string>("")

    const[refferalCode, setRefferalCode] = useState("")

    const navigate = useNavigate()

    const[params, setSearchParams] = useSearchParams()

    const context = useContext(AppContext)

    function updateUserInfo(){
        if(context.authToken !== null){
            getUserInfo(context.authToken)
            .then((fetchedTask: UserTask)=>{
                setUserTask(fetchedTask)

                if(!userTask.isDiscordAuthorize){
                    getDiscordAuthLink(context.authToken!)
                    .then((authLink: string)=>{
                        setDiscordAuthLink(authLink)
                    })
                }

                if(!userTask.isTwitterAuthorized){
                    getTwitterAuthLink(context.authToken!)
                    .then((authLink: string)=>{
                        setTwitterAuthLink(authLink)
                    })
                }
            })
        }
    }

    function handleChangeReferalCode(code: string){
        setRefferalCode(code)
        if(code.length === 5){
            submitReferralCode(code, context.authToken!)
            .then(()=>{
                updateUserInfo()
            })
            .catch(()=>{
                toast("Invalid code!")
            })
        }
    }

    function handleVerifyDiscordJoin(){
        verifyRole(context.authToken!)
        .then(()=>{
            updateUserInfo()
        })
    }

    function handleVerifyRole(){
        verifyRole(context.authToken!)
        .then(()=>{
            updateUserInfo()
        })

    }

    function handleVerifyTwitterFollow(){
        twitterFollow(context.authToken!)
        .then(()=>{
            updateUserInfo()
        })
    }

    function handleVerifyTwitterRetweet(){
        twitterRetweet(context.authToken!)
        .then(()=>{
            updateUserInfo()
        })
    }
    
    useEffect(()=>{
        updateUserInfo()
    }, [])

    useEffect(()=>{
        if(userTask.isBlinker && userTask.isDiscordFollowed && userTask.isReffcodeUsed && userTask.isRetweetDone && userTask.isTwitterFollowed && !userTask.refCode){
            getReferralCode(context.authToken!)
        }
    }, [userTask])

    useEffect(()=>{
        const code = params.get("code")
        const state = params.get("state")

        const ref = params.get("ref")

        if(code !== null && state === null){
            sendDiscordCode(code, context.authToken!)
            .then(()=>{
                updateUserInfo()
                navigate("/quests/social")
            })
        }

        if(code !== null && state !== null){
            authTwitter(code, context.authToken!)
            .then(()=>{
                updateUserInfo()
                navigate("/quests/social")
            })
        }

        if(ref !== null){
            if(userTask.isReffcodeUsed === false){
                setRefferalCode(ref)
                submitReferralCode(ref, context.authToken!)
                .then(()=>{
                    updateUserInfo()
                    navigate("/quests/social")
                })
                .catch(()=>{
                    toast("Invalid code!")
                    navigate("/quests/social")
                })
            }
        }
        else{
            navigate("/quests/social")
        }

    }, [])

    return(
        <div className="flex flex-col mt-[2%] 2xl:mt-[3%] gap-4 2xl:gap-6 z-30 w-full xl:w-2/3 2xl:w-1/2 ">
            <div className="flex items-center">
                <ScobedText color="grey">
                    <div className="text-grey text-xl uppercase px-2 py-1">
                        1
                    </div>
                </ScobedText>
                <div className="text-base text-white ml-10">
                    Connect wallet
                </div>
                <div className="w-[33%] ml-auto">
                    <DefaultButton className="text-sm py-3 bg-white text-black">
                        Completed
                    </DefaultButton>
                </div>
            </div>
            <div className="flex items-center">
                <ScobedText color="grey">
                    <div className="text-grey text-xl uppercase px-2 py-1">
                        2
                    </div>
                </ScobedText>
                <div className="text-base text-white ml-10">
                    Enter Invite Code
                </div>
                {userTask.isReffcodeUsed?
                    <div className="w-[33%] ml-auto flex">
                        <DefaultButton className="text-sm py-3 bg-white text-black">
                            Completed
                        </DefaultButton>
                    </div>
                    :
                    <div className="w-[33%]  ml-auto">
                        <VerificationInput
                            value={refferalCode}
                            length={5}
                            placeholder="_"
                            classNames={{
                                container: "w-full h-auto 2xl:gap-3 gap-2",
                                character: "border border-white bg-black text-xl uppercase text-white py-3",
                                characterInactive: "text-white",
                                characterSelected: "text-white outline-white",
                                characterFilled: "text-white",
                            }}
                            onChange={handleChangeReferalCode}
                        />
                    </div>
                }
            </div>
            <div className="flex items-center">
                <ScobedText color="grey">
                    <div className="text-grey text-xl uppercase px-2 py-1">
                        3
                    </div>
                </ScobedText>
                <div className="text-base text-white ml-10">
                    Follow <span className="underline duration-150 cursor-pointer hover:text-grey"><a href="https://twitter.com/blinkswap" target="_blank">@blinkswap</a></span> on X
                </div>
                {
                    userTask.isTwitterAuthorized?
                        userTask.isTwitterFollowed?
                        <div className="w-[33%] ml-auto flex">
                            <DefaultButton className="text-sm py-3 bg-white text-black">
                                Completed
                            </DefaultButton>
                        </div>
                        :
                        <div className="w-[33%]  ml-auto" onClick={handleVerifyTwitterFollow}>
                            <DefaultButton className="text-sm py-3">
                                Verify
                            </DefaultButton>
                        </div>
                        :
                        <div className="w-[33%]  ml-auto">
                            <DefaultButton className="text-sm py-3">
                                <a href={twitterAuthLink}>Authorize Twitter</a>
                            </DefaultButton>
                        </div>
                }
            </div>
            <div className="flex items-center">
                <ScobedText color="grey">
                    <div className="text-grey text-xl uppercase px-2 py-1">
                        4
                    </div>
                </ScobedText>
                <div className="text-base text-white ml-10">
                    Share your journey
                </div>
                {userTask.isTwitterAuthorized?
                    userTask.isRetweetDone?
                    <div className="w-[33%] ml-auto flex">
                        <DefaultButton className="text-sm py-3 bg-white text-black">
                            Completed
                        </DefaultButton>
                    </div>
                    :
                    <div className="w-[33%]  ml-auto" onClick={handleVerifyTwitterRetweet}>
                        <DefaultButton className="text-sm py-3">
                            Verify
                        </DefaultButton>
                    </div>
                    :
                    <div className="w-[33%]  ml-auto">
                        <DisableButton className="text-sm py-3">
                            Verify
                        </DisableButton>
                    </div>
                }
            </div>
            <div className="flex items-center">
                <ScobedText color="grey">
                    <div className="text-grey text-xl uppercase px-2 py-1">
                        5
                    </div>
                </ScobedText>
                <div className="text-base text-white ml-10">
                    Join <a href="https://discord.gg/blinkswap" target="_blank" className="underline duration-150 cursor-pointer hover:text-grey">Blink Discord</a>
                </div>
                {
                    userTask.isDiscordAuthorize?
                        userTask.isDiscordFollowed?
                        <div className="w-[33%] ml-auto flex">
                            <DefaultButton className="text-sm py-3 bg-white text-black">
                                Completed
                            </DefaultButton>
                        </div>
                        :
                        <div className="w-[33%]  ml-auto" onClick={handleVerifyDiscordJoin}>
                            <DefaultButton className="text-sm py-3">
                                Verify
                            </DefaultButton>
                        </div>
                        :
                        <div className="w-[33%]  ml-auto">
                            <DefaultButton className="text-sm py-3">
                                <a href={discordAuthLink}>Authorize Discord</a>
                            </DefaultButton>
                        </div>
                }
            </div>
            <div className="flex items-center">
                <ScobedText color="grey">
                    <div className="text-grey text-xl uppercase px-2 py-1">
                        6
                    </div>
                </ScobedText>
                <div className="text-base text-white ml-10">
                    Get role Blinker on Discord
                </div>
                {userTask.isDiscordAuthorize?
                    userTask.isBlinker?
                    <div className="w-[33%] ml-auto flex">
                        <DefaultButton className="text-sm py-3 bg-white text-black">
                            Completed
                        </DefaultButton>
                    </div>
                    :
                    <div className="w-[33%]  ml-auto" onClick={handleVerifyRole}>
                        <DefaultButton className="text-sm py-3">
                            Verify
                        </DefaultButton>
                    </div>
                    :
                    <div className="w-[33%]  ml-auto">
                        <DisableButton className="text-sm py-3">
                            Verify
                        </DisableButton>
                    </div>
                }
            </div>
        </div>
    )
}

export default SocialQuestTasksComponent
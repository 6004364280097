import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAccount } from "wagmi"
import ConnectRequirePage from "../../shared/ConnectRequirePage"

function RedirectPage(){
    const navigate = useNavigate()
    const[params, setSearchParams] = useSearchParams()

    const{ address, isConnected, chainId } = useAccount()

    useEffect(()=>{
        const ref = params.get("ref")
        if(ref !== null){
            navigate("/quests/social?ref=" + ref)
        }
        else{
            navigate("/swap")
        }
    }, [])

    if(!isConnected || window.ethereum === undefined){
        return <ConnectRequirePage/>
    }

    return(
        <div>

        </div>
    )
}

export default RedirectPage
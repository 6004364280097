import { createContext } from "react"

interface ContextInterface{
    width: number,
    authToken: string | null,
    setAuthToken?(authToken: React.SetStateAction<string | null>): void
}

export const AppContext = createContext<ContextInterface>(
    {
        width: 0,
        authToken: null
    }
)
import { blast, mainnet } from 'wagmi/chains';
import { http } from '@wagmi/core'
import { BLAST_RPC } from './constants';
import { createPublicClient, createWalletClient, custom, publicActions } from 'viem';

export const currentChain = blast

export const walletClient = window.ethereum&& createWalletClient({
    chain: currentChain,
    // @ts-ignore
    transport: custom(window.ethereum)
}).extend(publicActions)
// :
// createWalletClient({
//     chain: currentChain,
//     // @ts-ignore
//     transport: http("https://rpc.blast.io")
// }).extend(publicActions)
import axios from "axios"
import { UserPoints } from "../../interface/interface"

export async function getLeaderboard(){

    const resp = await axios.get("https://blinkswap.xyz/api/v1/leaderboard")

    const fetchedLeaderboards = resp.data.data

    const socialLeaderboard = fetchedLeaderboards.social_points
    const lpLeaderboard = fetchedLeaderboards.lp_points
    const swapLeaderboard = fetchedLeaderboards.swap_points

    const parsedSocialleadeboard = []
    const parsedLPleadeboard = []
    const parsedSwapleadeboard = []

    for(let position of socialLeaderboard){
        parsedSocialleadeboard.push({
            address: Object.keys(position)[0],
            points: Object.values(position)[0] as string
        })
    }

    for(let position of lpLeaderboard){
        parsedLPleadeboard.push({
            address: Object.keys(position)[0],
            points: Object.values(position)[0] as string
        })
    }

    for(let position of swapLeaderboard){
        parsedSwapleadeboard.push({
            address: Object.keys(position)[0],
            points: Object.values(position)[0] as string
        })
    }

    return{
        social: parsedSocialleadeboard,
        lp: parsedLPleadeboard,
        swap: parsedSwapleadeboard
    }
}

export async function getUserPoints(token: string): Promise<UserPoints>{
    const resp = await axios.get("https://blinkswap.xyz/api/v1/points", {
        headers:{
            'Authorization': `token ${token}`
        }
    })

    const userPoints = resp.data.data.user_points

    return{
        lpPoints: userPoints.lp_points,
        socialPoints: userPoints.social_points,
        swapPoints: userPoints.swap_points
    }
}
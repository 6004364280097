import { useEffect, useState } from "react"
import arrowBack from "../../assets/img/arrow_back.svg"
import plusIcon from "../../assets/img/plus_icon.svg"
import DefaultButton from "../../shared/DefaultButton"
import { ChoseType, LiquidFetchType, Reserves, Token, TradeType } from "../../interface/interface"
import { initialTokens } from "../../app/constants"
import { useAccount } from "wagmi"
import LiquidityTokensModal from "./LiquidityTokensModal"
import LiquidityInputComponent from "./LiquidityInputComponent"
import { addLiquidity, approve, checkApprove, getBalances, getPoolQuote, getReserves, getTokensPrice } from "../../app/ethereumFunctions"
import { formatUnits, parseUnits } from "viem"
import { formatBalance, formatRatio } from "../../app/utils/format"
import SwitchChainRequire from "../../shared/SwitchChainRequire"
import { useSearchParams } from "react-router-dom"

interface AddLiquidityPageProps{
    initialPair?: Token[],
    slippage: string | undefined,
    setSlippage(token: React.SetStateAction<string | undefined>): void
    onClose(state: React.SetStateAction<boolean>): void
}

function AddLiquidityPage({slippage, setSlippage, initialPair, onClose}: AddLiquidityPageProps){
    const{ address, isConnected, chainId } = useAccount()

    const[error, setError] = useState<string>()

    const[tokens, setTokens] = useState<Token[]>(initialTokens)

    const[modalType, setModalType] = useState<ChoseType>("in")

    const[tokenA, setTokenA] = useState<Token | undefined>(initialPair? initialPair[0] : tokens[0])
    const[tokenB, setTokenB] = useState<Token | undefined>(initialPair? initialPair[1] : tokens[2])

    const[amountA, setAmountA] = useState<string>("")
    const[amountB, setAmountB] = useState<string>("")

    const[isTokenAAprroved, setisTokenAAprroved] = useState<boolean>(true)
    const[isTokenBAprroved, setisTokenBAprroved] = useState<boolean>(true)

    const[isNewPool, setIsNewPool] = useState<boolean>(false)

    const[poolAmountA, setPoolAmountA] = useState<string>("")
    const[poolAmountB, setPoolAmountB] = useState<string>("")

    const[poolShares, setPoolShares] = useState<string>("-")

    const[fetchType, setFetchType] = useState<LiquidFetchType>("tokenA")

    const[isModalOpen, setIsModalOpen] = useState(false)

    const[params, setSearchParams] = useSearchParams()

    function handleAddLiquidity(){
        if(tokenA && tokenB && amountA && amountB && slippage && chainId === 81457){
            if(!isTokenAAprroved){
                approve(address!.toString(), tokenA)
                .then((status)=>{
                    setisTokenAAprroved(status)
                    updateBalances()
                })
                return
            }

            if(!isTokenBAprroved){
                approve(address!.toString(), tokenB)
                .then((status)=>{
                    setisTokenBAprroved(status)
                    updateBalances()
                })
                return
            }

            addLiquidity(address!.toString(), tokenA, tokenB, amountA, amountB, slippage)
            .then((res)=>{
                if(res === true){
                    updateBalances()
                }
            })
        }
    }

    function handleSlippageChange(event: React.ChangeEvent<HTMLInputElement>){
        const formatedValue = event.target.value.replace(/[^0-9.,]/g, '').replace(",", ".").replace( /^([^\.]*\.)|\./g, '$1' )
        if(Number(formatedValue) >= 0 && Number(formatedValue) <= 100){
            setSlippage(formatedValue)
        }
    }

    function handleOnBlur(event: React.ChangeEvent<HTMLInputElement>){
        if(!event.target.value){
            setSlippage("0.5")
        }

        if(Number(event.target.value) === 0){
            setSlippage("0")
        }
    }

    function updateBalances(){
        if(address && chainId === 81457){
            let newTokens: Token[] = []

            if(initialPair){
                const newTokensFind = initialPair.filter((token: Token)=>{
                    const duplicateTokens = initialTokens.filter((inititalToken: Token)=>{
                        return inititalToken.address === token.address
                    })

                    return duplicateTokens.length === 0? true : false
                })

                newTokens = newTokensFind
            }

            getBalances([...tokens, ...newTokens], address)
            .then((tokens)=>{
                getTokensPrice(tokens)
                .then((tokens)=>{
                    setTokens(tokens)

                    setTokenA(tokens.find((token)=>{
                        return tokenA?.name === token.name
                    }))
                    setTokenB(tokens.find((token)=>{
                        return tokenB?.name === token.name
                    }))
                })
            })
        }
    }

    useEffect(()=>{
        const paramsTokenA = params.get("tokenA")

        if(params.get("tokenA") !== null && params.get("tokenA") !== null){

        }
    })

    useEffect(()=>{
        if(tokenA && tokenB && chainId === 81457){
            getReserves(tokenA, tokenB)
            .then(({tokenAReserve, tokenBReserve, error})=>{
                if(!error){
                    setPoolAmountA(formatUnits(tokenAReserve!, Number(tokenA.decimals)))
                    setPoolAmountB(formatUnits(tokenBReserve!, Number(tokenB.decimals)))

                    setIsNewPool(false)
                }
                if(error){
                    setIsNewPool(true)
                }
            })
        }
    }, [tokenA, tokenB])

    useEffect(()=>{
        const Interval = setInterval(()=>{
            if(tokenA && tokenB && chainId === 81457){
                getReserves(tokenA, tokenB)
                .then(({tokenAReserve, tokenBReserve, error})=>{
                    if(!error){
                        setPoolAmountA(formatUnits(tokenAReserve!, Number(tokenA.decimals)))
                        setPoolAmountB(formatUnits(tokenBReserve!, Number(tokenB.decimals)))
    
                        setIsNewPool(false)
                    }
                    if(error){
                        setIsNewPool(true)
                    }
                })
            }
        }, 10000)

        return()=>{
            clearInterval(Interval)
        }
    }, [tokenA, tokenB])

    useEffect(()=>{
        const Debounce = setTimeout(()=>{
            if(tokenA && tokenB && Number(amountA) !== 0 && fetchType === "tokenA" && !isNewPool && chainId === 81457){
                getPoolQuote(tokenA, tokenB, amountA)
                .then(({fetchedAmountB, newAmountAinPool})=>{
                    const shares = ((Number(amountA) / Number(formatUnits(newAmountAinPool, Number(tokenA.decimals)))) * 100).toFixed(2)
                    setPoolShares(shares)
                    setAmountB(formatUnits(fetchedAmountB, Number(tokenB.decimals)))
                })
            }
        }, 1000)

        return()=>{
            clearTimeout(Debounce)
        }
    }, [tokenA, tokenB, amountA, isNewPool])

    useEffect(()=>{
        const Debounce = setTimeout(()=>{
            if(tokenA && tokenB && Number(amountB) !== 0 && fetchType === "tokenB" && !isNewPool && chainId === 81457){
                getPoolQuote(tokenB, tokenA, amountB)
                .then(({fetchedAmountB, newAmountAinPool})=>{
                    const shares = ((Number(amountB) / Number(formatUnits(newAmountAinPool, Number(tokenB.decimals)))) * 100).toFixed(2)
                    setPoolShares(shares)
                    setAmountA(formatUnits(fetchedAmountB, Number(tokenA.decimals)))
                })
            }
        }, 1000)

        return()=>{
            clearTimeout(Debounce)
        }
    }, [tokenA, tokenB, amountB, isNewPool])

    useEffect(()=>{
        const Interval = setInterval(()=>{
            if(tokenA && tokenB && Number(amountB) !== 0 && fetchType === "tokenB" && !isNewPool && chainId === 81457){
                getPoolQuote(tokenB, tokenA, amountB)
                .then(({fetchedAmountB, newAmountAinPool})=>{
                    const shares = ((Number(amountB) / Number(formatUnits(newAmountAinPool, Number(tokenB.decimals)))) * 100).toFixed(2)
                    setPoolShares(shares)
                    setAmountA(formatUnits(fetchedAmountB, Number(tokenA.decimals)))
                })
            }

            if(tokenA && tokenB && Number(amountA) !== 0 && fetchType === "tokenA" && !isNewPool && chainId === 81457){
                getPoolQuote(tokenA, tokenB, amountA)
                .then(({fetchedAmountB, newAmountAinPool})=>{
                    const shares = ((Number(amountA) / Number(formatUnits(newAmountAinPool, Number(tokenA.decimals)))) * 100).toFixed(2)
                    setPoolShares(shares)
                    setAmountB(formatUnits(fetchedAmountB, Number(tokenB.decimals)))
                })
            }

        }, 10000)

        return()=>{
            clearInterval(Interval)
        }

    }, [tokenA, tokenB, amountA, amountB, isNewPool])

    useEffect(()=>{
        if(isNewPool && chainId === 81457){
            setPoolAmountA(amountA)
            setPoolAmountB(amountB)
            setPoolShares("100")
        }
    }, [tokenA, tokenB, amountA, amountB, isNewPool])

    useEffect(()=>{
        updateBalances()
    }, [])

    useEffect(()=>{
        const Interval = setInterval(()=>{
            updateBalances()
        }, 10000)

        return()=>{
            clearInterval(Interval)
        }
    }, [address, tokens, tokenA, tokenB])

    useEffect(()=>{
        if(tokenA && tokenB && amountA && amountB && chainId === 81457){
            checkApprove(address!.toString(), tokenA, amountA)
            .then((res)=>{
                if(res === true){
                    setisTokenAAprroved(true)
                    checkApprove(address!.toString(), tokenB, amountB)
                    .then((res)=>{
                        if(res === false){
                            setisTokenBAprroved(false)
                            setError(`Approve ${tokenB.name}`)
                        }
                        else{
                            setisTokenBAprroved(true)
                            if(Number(tokenA?.balance) < Number(amountA) || Number(tokenB?.balance) < Number(amountB) ){
                                setError("insufficient Balance")
                            }
                            else{
                                setError("")
                            }
                        }
                    })
                }
                else{
                    setisTokenAAprroved(false)
                    setError(`Approve ${tokenA.name}`)
                }
            })
        }

    }, [tokenA, tokenB, amountA, amountB])

    function getRatio(a?: string, b?: string){
        const res = Number(a) / Number(b)
        return res
    }

    return(
        <div className="w-1/3 flex flex-col z-30 my-14 2xl:my-20 max-w-[430px] min-w-[350px]">
            {
                isModalOpen&& <LiquidityTokensModal tokenA={tokenA} tokenB={tokenB} type={modalType} setTokenA={setTokenA} setTokenB={setTokenB} onClose={setIsModalOpen} setTokens={setTokens} tokens={tokens}/> 
            }
            <div className="flex w-full gap-4">
                <div className="border border-white flex items-center justify-center size-[38px] hover:bg-white cursor-pointer fill-black" onClick={()=>{onClose(false)}}>
                    <svg width="22" height="22" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="hover:fill-black duration-200 fill-white">
                        <path d="M3.67279 14.8416L15.5745 26.423L14.3662 27.6026L0.431641 14L14.3708 0.393066L15.5709 1.54938L3.67834 13.1585H33.2942V14.8416H3.67279Z"/>
                    </svg>
                </div>
                <div className="text-sm text-black py-2 bg-white justify-center items-center uppercase flex grow">
                    Add Liquidity
                </div>
            </div>
            <div className="mt-4 z-30">
                <LiquidityInputComponent
                    label="Token A"
                    modalType={"in"}
                    amount={amountA}
                    setAmount={setAmountA}
                    setModalType={setModalType}
                    setFetchType={setFetchType}
                    openModal={setIsModalOpen}
                    visibleToken={tokenA}
                />
            </div>

            <div className="flex justify-center my-2 z-30">
                <img src={plusIcon} alt="" className="size-5"/>
            </div>

            <div className="z-30">
                <LiquidityInputComponent
                    label="Token B"
                    modalType={"out"}
                    amount={amountB}
                    setAmount={setAmountB}
                    setModalType={setModalType}
                    setFetchType={setFetchType}
                    openModal={setIsModalOpen}
                    visibleToken={tokenB}
                />
            </div>

            <div className="flex flex-col gap-5 px-5 py-3 border border-grey mt-4">
                <div className="flex justify-between items-center">
                    <div className="text-xs text-white uppercase">
                        Initial prices and pool share
                    </div>
                    {isNewPool&&
                        <div className="text-xs text-grey border border-grey px-1">
                            New Pool
                        </div>
                    }
                </div>
                <div className="flex gap-2 w-full text-white text-sm font-thin">
                    <div className="w-full">
                        <div className="w-fit flex flex-col gap-1 items-center">
                            <div>
                                {formatRatio(getRatio(poolAmountA, poolAmountB).toString())}
                            </div>
                            <div className="text-xs text-grey">
                                {`${tokenA? tokenA.name : "-"} per ${tokenB? tokenB.name : "-"}`}
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col gap-1 items-center">
                            <div>
                                {formatRatio(getRatio(poolAmountB, poolAmountA).toString())}
                            </div>
                            <div className="text-xs text-grey">
                                {`${tokenB? tokenB.name : "-"} per ${tokenA? tokenA.name : "-"}`}
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="w-fit ml-auto flex flex-col gap-1 items-center">
                            <div>
                                {`${poolShares} %`}
                            </div>
                            <div className="text-xs text-grey">
                                Share pool
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4" onClick={handleAddLiquidity}>
                <DefaultButton className="py-2 text-sm">
                    {error?
                        error
                        :
                        "Add Liquidity"
                    }
                </DefaultButton>
            </div>
            <div className="flex justify-between text-grey items-center text-xs mt-2">
                <div>
                    Slippage
                </div>
                <div className="flex gap-1 items-center">
                    <input type="text" className="w-8 py-1 bg-opacity-0 bg-black outline-none border border-black text-white placeholder:text-grey text-center focus:border focus:border-white"
                        value={slippage}
                        onChange={handleSlippageChange}
                        onBlur={handleOnBlur}
                    />
                    <div>
                        %
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddLiquidityPage
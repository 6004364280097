import axios from "axios"



export async function getTwitterAuthLink(token: string){
    const resp = await axios.get("https://blinkswap.xyz/api/v1/follow-twitter", {
        headers:{
            'Authorization': `token ${token}`,
        }
    })

    return resp.data.data.auth_link as string
}

export async function authTwitter(code: string, token: string){
    const resp = await axios.post("https://blinkswap.xyz/api/v1/twitter-response",
        {
            twitter_code: code
        },
        {
            headers:{
                'Authorization': `token ${token}`
            }
        },
    )
}

export async function twitterFollow(token: string){
    const resp = await axios.get("https://blinkswap.xyz/api/v1/twitter/1", {
        headers:{
            'Authorization': `token ${token}`
        }
    })
}

export async function twitterRetweet(token: string){
    const resp = await axios.get("https://blinkswap.xyz/api/v1/twitter/2", {
        headers:{
            'Authorization': `token ${token}`
        }
    })
}
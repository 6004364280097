import arrowDown from "../../assets/img/arrow_down.svg"
import swapBg from "../../assets/img/swap_page_bg.svg"
import { useEffect, useState, useContext } from "react"
import DefaultButton from "../../shared/DefaultButton"
import InputComponent from "./SwapInputComponent"
import TokensModal from "./SwapTokensModal"
import { initialTokens } from "../../app/constants"
import { ChoseType, Reserves, Token, Trade, TradeType } from "../../interface/interface"
import { approve, checkApprove, getAmountIn, getAmountOut, getBalances, getReserves, getTokensPrice, swap } from "../../app/ethereumFunctions"
import { useAccount } from "wagmi"
import SwapInfoComponent from "./SwapInfoComponent"
import ConnectRequirePage from "../../shared/ConnectRequirePage"
import { Link } from "react-router-dom"
import SwitchChainRequire from "../../shared/SwitchChainRequire"
import ImpactModal from "./ImpactModal"
import { formatUnits, parseUnits } from "viem"
import { AppContext } from "../../app/app.context"


function SwapPage(){
    const{ address, isConnected, chainId } = useAccount()

    const[error, setError] = useState<string>()

    const[tokens, setTokens] = useState<Token[]>(initialTokens)

    const[modalType, setModalType] = useState<ChoseType>("in")
    const[tradeType, setTradeType] = useState<TradeType>("exactIn")

    const[tokenIn, setTokenIn] = useState<Token | undefined>(tokens[0])
    const[tokenOut, setTokenOut] = useState<Token | undefined>()

    const[amountIn, setAmountIn] = useState<string>("")
    const[amountOut, setAmountOut] = useState<string>("")

    const[slippage, setSlippage] = useState<string | undefined>("0.5")

    const[isModalOpen, setIsModalOpen] = useState(false)
    const[isImpactModalOpen, setIsImpactModalOpen] = useState(false)

    const[isApproved, setIsApproved] = useState(true)

    const[reserves, setReserves] = useState<Reserves | undefined>()

    const context = useContext(AppContext)

    function getPriceImpact(){
        const allTokens = reserves?.tokenInReserve! * reserves?.tokenOutReserve!
        const newInPool = reserves?.tokenInReserve! + parseUnits(amountIn!, Number(tokenIn?.decimals!))

        const newOutPool = allTokens / newInPool

        const amountOut = reserves?.tokenOutReserve! - newOutPool
        const estimatePriceImpact = Number(formatUnits(amountOut, Number(tokenOut?.decimals))) / Number(formatUnits(newOutPool, Number(tokenOut?.decimals))) * 100

        return estimatePriceImpact.toFixed(2)
    }

    function handleSwap(allowImpact: boolean){
        if(!isApproved){
            approve(address!.toString(), tokenIn!)
            .then((status)=>{
                setIsApproved(status)
            })
        }
        else{
            if(tokenIn && tokenOut && amountIn && amountOut && slippage && !error && chainId === 81457){

                if(Number(getPriceImpact()) > 2 && !allowImpact){
                    setIsImpactModalOpen(true)
                    return
                }
                
                const trade: Trade = {
                    tradeType,
                    tokenIn,
                    tokenOut,
                    amountIn,
                    amountOut,
                    slippage
                }

                swap(address!.toString(), trade)
                .then((res)=>{
                    if(res === true){
                        getBalances(tokens, address!.toString())
                        .then((tokens)=>{
                            setTokens(tokens)

                            setTokenIn(tokens.find((token)=>{
                                return tokenIn?.name === token.name
                            }))
                            setTokenOut(tokens.find((token)=>{
                                return tokenOut?.name === token.name
                            }))
                        })
                    }
                })
            }
        }
    }

    useEffect(()=>{
        if(tokenIn && tokenOut && chainId === 81457){
            getReserves(tokenIn, tokenOut)
            .then(({tokenAReserve, tokenBReserve, error})=>{
                if(!error){
                    setReserves({
                        tokenInReserve: tokenAReserve,
                        tokenOutReserve: tokenBReserve
                    })
                }
            })
        }
    }, [tokenIn, tokenOut, chainId])

    useEffect(()=>{
        const Interval = setInterval(()=>{
            if(tokenIn && tokenOut && chainId === 81457){
                getReserves(tokenIn, tokenOut)
                .then(({tokenAReserve, tokenBReserve, error})=>{
                    if(!error){
                        setReserves({
                            tokenInReserve: tokenAReserve,
                            tokenOutReserve: tokenBReserve
                        })
                    }
                })
            }
        })

        return()=>{
            clearInterval(Interval)
        }
        
    }, [tokenIn, tokenOut])

    useEffect(()=>{
        const Debounce = setTimeout(()=>{
            if(Number(amountIn) !== 0 && tokenIn && tokenOut && tradeType === "exactIn" && chainId === 81457){
                getAmountOut(amountIn, tokenIn, tokenOut)
                .then(({amountOut, error})=>{
                    if(error !== undefined){
                        setError(error)
                        setAmountOut("")
                    }
    
                    if(error === undefined){
                        setAmountOut(amountOut)
                    }
                })
                checkApprove(address!.toString(), tokenIn, amountIn)
                .then((res: boolean)=>{
                    setIsApproved(res)
                })
            }
        }, 1000)

        return()=>{
            clearTimeout(Debounce)
        }
    }, [amountIn, tokenIn, tokenOut, chainId])

    useEffect(()=>{
        const Debounce = setTimeout(()=>{
            if(Number(amountOut) !== 0 && tokenIn && tokenOut && tradeType === "exactOut" && chainId === 81457){
                getAmountIn(amountOut, tokenIn, tokenOut)
                .then(({amountIn, error})=>{
                    if(error !== undefined){
                        setError(error)
                        setAmountIn("")
                    }
    
                    if(error === undefined){
                        setAmountIn(amountIn)
                    }
                })
            }
        }, 1000)

        return()=>{
            clearTimeout(Debounce)
        }
    }, [amountOut, tokenIn, tokenOut, chainId])

    useEffect(()=>{
        const Interval = setInterval(()=>{
            if(Number(amountOut) !== 0 && tokenIn && tokenOut && tradeType === "exactOut" && chainId === 81457){
                getAmountIn(amountOut, tokenIn, tokenOut)
                .then(({amountIn, error})=>{
                    if(error !== undefined){
                        setError(error)
                        setAmountIn("")
                    }
    
                    if(error === undefined){
                        setAmountIn(amountIn)
                        setError("")
                    }
                })
            }

            if(Number(amountIn) !== 0 && tokenIn && tokenOut && tradeType === "exactIn" && chainId === 81457){
                getAmountOut(amountIn, tokenIn, tokenOut)
                .then(({amountOut, error})=>{
                    if(error !== undefined){
                        setError(error)
                        setAmountOut("")
                    }
    
                    if(error === undefined){
                        setAmountOut(amountOut)
                    }
                })
                checkApprove(address!.toString(), tokenIn, amountIn)
                .then((res: boolean)=>{
                    setIsApproved(res)
                })
            }
        }, 10000)

        return()=>{
            clearInterval(Interval)
        }
    }, [amountOut, tokenIn, tokenOut, amountIn])

    useEffect(()=>{
        if(address && chainId === 81457){
            getBalances([...tokens], address)
            .then((newTokens)=>{
                getTokensPrice(newTokens)
                .then((newTokens)=>{
                    setTokens(newTokens)

                    setTokenIn(newTokens.find((token)=>{
                        return tokenIn?.name === token.name
                    }))
                    setTokenOut(newTokens.find((token)=>{
                        return tokenOut?.name === token.name
                    }))
                })
            })
        }
    }, [address, chainId])

    useEffect(()=>{
        const Interval = setInterval(()=>{
            if(address && chainId === 81457){
                getBalances([...tokens], address)
                .then((newTokens)=>{
                    getTokensPrice(newTokens)
                    .then((newTokens)=>{
                        setTokens(newTokens)
    
                        setTokenIn(newTokens.find((token)=>{
                            return tokenIn?.name === token.name
                        }))
                        setTokenOut(newTokens.find((token)=>{
                            return tokenOut?.name === token.name
                        }))
                    })
                })
            }
        }, 10000)

        return()=>{
            clearInterval(Interval)
        }
    }, [address, chainId, tokenIn, tokenOut, amountIn, amountOut])

    useEffect(()=>{
        if(!amountIn && amountOut && chainId === 81457){
            setAmountOut("")
        }
    }, [amountIn])

    useEffect(()=>{
        if(Number(tokenIn?.balance) < Number(amountIn) && chainId === 81457){
            setError("insufficient Balance")
        }
        else{
            setError("")
        }
    }, [amountIn, amountOut, tokenIn])

    if(!isConnected || window.ethereum === undefined){
        return <ConnectRequirePage/>
    }

    return(
        <div className="w-full flex flex-col grow items-center tracking-wide">
            {
                chainId !== 81457&&
                    <div className="fixed flex items-center justify-center bg-black bg-opacity-50 top-0 left-0 w-full h-full overflow-hidden z-40 backdrop-blur-sm">
                        <SwitchChainRequire/>
                    </div>
            }
            {
                isModalOpen&& <TokensModal tokenIn={tokenIn} tokenOut={tokenOut} type={modalType} setTokenIn={setTokenIn} setTokenOut={setTokenOut} onClose={setIsModalOpen} setTokens={setTokens} tokens={tokens}/> 
            }
            {
                isImpactModalOpen&& <ImpactModal onClose={setIsImpactModalOpen} handleSwap={handleSwap}/>
            }
            <div className="w-full relative">
                <img src={swapBg} alt="" className="w-[120%] h-full fixed top-0 left-0 object-cover"/>
            </div>
            <div className="w-1/3 flex flex-col my-14 2xl:mt-20 mb-32 max-w-[430px] min-w-[350px]">
                <div className="flex w-full gap-4 z-30">
                    <div className="w-full">
                        <DefaultButton className="text-black py-2 bg-white text-sm">
                            Swap
                        </DefaultButton>
                    </div>
                    <div className="w-full">
                        <Link to="/pool">
                            <DefaultButton className="text-sm py-2">
                                Pool
                            </DefaultButton>
                        </Link>
                    </div>
                </div>

                <div className="mt-4 z-30">
                    <InputComponent 
                        label="From"
                        modalType="in"
                        amount={amountIn}
                        setAmount={setAmountIn}
                        setModalType={setModalType}
                        setTradeType={setTradeType}
                        openModal={setIsModalOpen}
                        visibleToken={tokenIn}
                    />
                </div>

                <div className="flex justify-center my-3 z-30">
                    <img src={arrowDown} alt="" className="size-5"/>
                </div>

                <div className="z-30">
                    <InputComponent
                        label="To"
                        modalType="out"
                        amount={amountOut}
                        setAmount={setAmountOut}
                        setModalType={setModalType}
                        setTradeType={setTradeType}
                        openModal={setIsModalOpen}
                        visibleToken={tokenOut}
                    />
                </div>
                <div onClick={()=>{handleSwap(false)}} className="z-30">
                    <DefaultButton className="text-sm mt-4 z-30 py-2">
                        {error?
                            error
                            : 
                            isApproved?
                                "Swap"
                                :
                                "Approve"
                        }
                    </DefaultButton>
                </div>

                <div className="z-30 mt-3 relative w-full">
                    <div className="absolute w-full">
                        <SwapInfoComponent reserves={reserves} tokenIn={tokenIn} tokenOut={tokenOut} amountIn={amountIn} amountOut={amountOut} slippage={slippage} setSlippage={setSlippage}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwapPage
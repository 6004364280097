import { Pair } from "../../interface/interface";

export function findAllETH(pairs: Pair[]){
    const ethAddress = "0x4300000000000000000000000000000000000004"
    let totalETH: number = 0

    for(let pair of pairs){
        if(pair.tokenA.address === ethAddress){
            totalETH += Number(pair.pooledAmountA)
        }

        if(pair.tokenB.address === ethAddress){
            totalETH += Number(pair.pooledAmountB)
        }
    }

    return totalETH.toString()
}
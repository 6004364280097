import xLogo from "../../assets/img/x_logo.svg"
import dsLogo from "../../assets/img/ds_logo.svg"
import gitLogo from "../../assets/img/git_logo.svg"

function FooterComponent(){
    return(
        <div className="w-full flex justify-end mt-auto">
            <div className="flex gap-7 items-center z-30">
                <a href="https://twitter.com/blinkswap" target="_blank"><img src={xLogo} alt="" className="cursor-pointer size-5"/></a>
                <a href="https://discord.gg/blinkswap" target="_blank"><img src={dsLogo} alt="" className="cursor-pointer size-5"/></a>
                <a href="https://docs.blinkswap.xyz/" target="_blank"><img src={gitLogo} alt="" className="cursor-pointer size-5"/></a>
            </div>
        </div>
    )
}

export default FooterComponent
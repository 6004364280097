import { useState } from "react"
import dropDownArrow from "../../assets/img/dropdown_arrow.svg"
import { Pair, Token } from "../../interface/interface"
import TokenIcon from "../../shared/TokenIcon"
import DefaultButton from "../../shared/DefaultButton"
import { formatBalance, formatRatio } from "../../app/utils/format"

interface PoolInfoComponentProps{
    pair: Pair,
    onRemove(pair: Pair): void,
    onClose(state: React.SetStateAction<boolean>): void,
    setPair(pair: React.SetStateAction<Token[] | undefined>): void
}

function PoolInfoComponent({pair, onRemove, onClose, setPair}: PoolInfoComponentProps){
    const[isActiveFullInfo, setIsActiveFullInfo] = useState(false)


    function handleAdd(){
        setPair([pair.tokenA, pair.tokenB])
        onClose(true)
    }

    function handleRemove(){
        onRemove(pair)
    }

    function getShare(div: number){
        const percentDiv = div * 100

        if(percentDiv < 0.01){
            return "< 0.01"
        }

        return percentDiv.toFixed(2)
    }

    return(
        <div className="w-full border border-white p-2 flex flex-col gap-6">
            <div className="flex justify-between items-center px-1">
                <div className="flex gap-4 items-center">
                    <div className="flex gap-1">
                        <TokenIcon icon={pair.tokenA.icon!} className="size-[26px]"/>
                        <TokenIcon icon={pair.tokenB.icon!} className="size-[26px]"/>
                    </div>
                    <div className="text-white text-base font-thin">
                        {`${pair.tokenA.name}/${pair.tokenB.name}`}
                    </div>
                </div>
                <div className="mr-3 cursor-pointer" onClick={()=>{setIsActiveFullInfo(!isActiveFullInfo)}}>
                    <img src={dropDownArrow} alt="" className="size-3"/>
                </div>
            </div>
            {isActiveFullInfo&&
                <div className="flex flex-col gap-2 text-grey text-sm px-3">
                    <div className="flex justify-between items-center">
                        <div>
                            {`Pooled ${pair.tokenA.name}:`}
                        </div>
                        <div className="flex gap-2 items-center">
                            <div>
                                {formatBalance(pair.pooledAmountA)}
                            </div>
                            <TokenIcon icon={pair.tokenA.icon!} className="size-5"/>
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div>
                            {`Pooled ${pair.tokenB.name}:`}
                        </div>
                        <div className="flex gap-2 items-center">
                            <div>
                                {formatBalance(pair.pooledAmountB)}
                            </div>
                            <TokenIcon icon={pair.tokenB.icon!} className="size-5"/>
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div>
                            Your pool tokens:
                        </div>
                        <div>
                            {formatBalance(pair.userLPAmount)}
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div>
                            Your pool share:
                        </div>
                        <div>
                            {`${getShare(Number(pair.userLPAmount) / Number(pair.totalLPAmount))} %`}
                        </div>
                    </div>
                </div>
            }
            {isActiveFullInfo&&
                <div className="flex gap-5 py-3 px-3">
                    <div className="w-full" onClick={handleAdd}>
                        <DefaultButton className="text-sm py-2">
                            Add
                        </DefaultButton>
                    </div>
                    <div className="w-full" onClick={handleRemove}>
                        <DefaultButton className="text-sm py-2">
                            Remove
                        </DefaultButton>
                    </div>
                </div>
            }
        </div>
    )
}

export default PoolInfoComponent
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import RainbowWrapper from './app/utils/RainbowWrapper';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RainbowWrapper>
    <App />
  </RainbowWrapper>
);

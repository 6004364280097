import { connectorsForWallets, darkTheme, getDefaultConfig, RainbowKitProvider, Theme } from '@rainbow-me/rainbowkit';
import { rainbowWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { WagmiProvider, createConfig,  } from 'wagmi';
import { blast, blastSepolia, mainnet } from 'wagmi/chains';
import { http } from '@wagmi/core'
import { QueryClientProvider,QueryClient } from "@tanstack/react-query";
import { ReactNode } from 'react';
import { createClient, custom } from 'viem';
import { injected } from 'wagmi/connectors';
import merge from 'lodash.merge';

interface RainbowWrapperInterface{
  children: ReactNode
}

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [rainbowWallet, walletConnectWallet],
    },
  ],
  {
    appName: 'My RainbowKit App',
    projectId: '8f678807e33157ffdd849bcef1970716',
  }
);

export const configWagmi = createConfig({
  chains: [blast],
  connectors: [injected(), ...connectors],
  client({ chain }) {
    return createClient({ chain, transport: custom(window.ethereum!) })
  },
})


const queryClient = new QueryClient();

const myTheme = merge(darkTheme(), {
  fonts:{
    body: "TG Frekuent Mono Variable"
  },
  radii: {
    actionButton: '0',
    connectButton: '0',
    menuButton: '0',
    modal: '0',
    modalMobile: '0',
  },
  colors: {
    profileForeground: '#090909',
    modalBackground: "#090909",
    generalBorder: "#F1F1F1",
    modalBorder: "#F1F1F1"
  }
} as Theme);

function RainbowWrapper({children}: RainbowWrapperInterface){
  return (
    <WagmiProvider config={configWagmi}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider initialChain={blast} modalSize='compact' locale='en-US' theme={myTheme}>
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default RainbowWrapper
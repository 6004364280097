import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HeaderComponent from './widgets/Header/HeaderComponent';
import { AppContext } from './app/app.context';
import { useResize } from './app/hooks/useResize';
import { cn } from './app/utils/cn';
import QuestsPage from './pages/Quests/QuestsPage';
import FooterComponent from './widgets/Footer/FooterComponent';
import SwapPage from './pages/Swap/SwapPage';
import SocialQuestPage from './pages/Quests/SocialQuest/SocialQuestPage';
import RainbowWrapper from './app/utils/RainbowWrapper';
import '@rainbow-me/rainbowkit/styles.css';
import LeaderboardPage from './pages/Leaderboard/LeaderboardPage';
import PoolPage from './pages/Pool/PoolPage';
import RedirectPage from './pages/Redirect/RedirectPage';
import { useAccount } from 'wagmi';
import { findAuthToken } from './app/utils/findAuthToken';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LiquidityQuestPage from './pages/Quests/LiquidityQuest/LiquidityQuestPage';

function App() {
  const { address } = useAccount()
  const[authToken, setAuthToken] = useState(findAuthToken(address?.toString()))
  const{ width } = useResize()

  const context = {
    width: width,
    authToken: authToken,
    setAuthToken: setAuthToken
  }

  useEffect(()=>{
    setAuthToken(findAuthToken(address?.toString()))
  }, [address])


  return (
      <BrowserRouter>
        <AppContext.Provider value={context} >
          <div className="bg-black w-full flex flex-col min-h-screen py-5 px-8  overflow-hidden font-variable">
            <HeaderComponent/>
            <Routes>
              <Route path="/" element={<RedirectPage/>}/>
              <Route path='/swap' element={<SwapPage/>}/>
              <Route path="/quests" element={<QuestsPage/>}/>
              <Route path="/quests/social/" element={<SocialQuestPage/>}/>
              <Route path="/quests/liquidity/" element={<LiquidityQuestPage/>}/>
              <Route path="/leaderboard" element={<LeaderboardPage/>}/>
              <Route path="/pool" element={<PoolPage/>}/>
            </Routes>
            <FooterComponent/>
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
              transition={Slide}
              limit={1}
              bodyClassName={"font-variable text-white text-center text-sm"}
              toastClassName={"border border-white"}
            />
          </div>
        </AppContext.Provider>
      </BrowserRouter>
  );
}

export default App;
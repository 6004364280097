import { factoryABI, erc20ABI, wethABI, pairABI, routerABI, poolsHelperABI } from "./utils/abi"
import { Pair, Token, Trade } from "../interface/interface";
import { walletClient } from "./providers";
import { factoryAddress, initialTokens, poolsHelperAddress, routerAddress } from "./constants";
import { Abi, erc20Abi, formatEther, formatUnits, getAddress, parseUnits } from "viem";
import { blast } from "viem/chains";
import { getPrice } from "./api/priceApi";

const client = walletClient

const MAX_UINT = "115792089237316195423570985008687907853269984665640564039457584007913129639935"

async function getDecimals(token: Token){
    const decimals = await client.readContract({
        address: getAddress(token.address),
        abi: erc20Abi,
        functionName: "decimals",
    })

    return decimals
}

export async function switchChain(){
    await client.switchChain({id: blast.id})
}

export async function getUserSignature(userAddress: string, message: string){
    const signature = await client.signMessage({
        account: getAddress(userAddress),
        message: message
    })

    return signature as string
}

export async function getPairAddress(addressTokenA: string, addressTokenB: string){
    const pairAddress = await client.readContract({
        address: getAddress(factoryAddress),
        abi: factoryABI,
        functionName: "getPair",
        args:[getAddress(addressTokenA), getAddress(addressTokenB)]
    })

    return pairAddress
}

export async function checkApprove(userAddress: string, token: Token, amount: string){
    try{
        if(token.name === "ETH"){
            return true
        }

        const allowance = await client.readContract({
            address: getAddress(token.address),
            abi: erc20Abi,
            functionName: "allowance",
            args:[getAddress(userAddress), routerAddress]
        })

        if(allowance < parseUnits(amount, Number(token.decimals))){
            return false
        }
        else{
            return true
        }
    }
    catch(e){
        console.log(e)
        return false
    }
}

export async function getToken(tokenAddress: string, userAddress?: string){
    try{

        const calls: any = [
            {
                address: getAddress(tokenAddress),
                abi: erc20Abi,
                functionName: "symbol"
            },
            {
                address: getAddress(tokenAddress),
                abi: erc20Abi,
                functionName: "decimals"
            }
        ]

        if(userAddress){
            calls.push(
                {
                    address: getAddress(tokenAddress),
                    abi: erc20Abi,
                    functionName: "balanceOf",
                    args: [getAddress(userAddress)]
                }
            )
        }

        const tokenInfo = await client.multicall({
            contracts: [
                ...calls
            ]
        })

        const newToken: Token = {
            address: tokenAddress,
            name: tokenInfo[0].result as string,
            decimals: tokenInfo[1].result as string
        }

        if(userAddress){
            newToken.balance = formatUnits(tokenInfo[2].result as bigint, Number(tokenInfo[1].result))
        }

        return {
            token: newToken
        }
    }
    catch{
        return{
            error: "Token not found"
        }
    }
}

export async function getBalances(tokens: Token[], userAddress: string){
    try{
        const callForBalance = tokens.map((token: Token)=>{
            return{
                address: getAddress(token.address),
                abi: erc20Abi,
                functionName: "balanceOf",
                args: [getAddress(userAddress)]
            }
        })

        const callForDecimals = tokens.map((token: Token)=>{
            return{
                address: getAddress(token.address),
                abi: erc20Abi,
                functionName: "decimals",
            }
        })

        const balances = await client.multicall({
            contracts: [...callForBalance]
        })

        const decimals = await client.multicall({
            contracts: [...callForDecimals]
        })

        const ethBalance = await client.getBalance({
            address: getAddress(userAddress)
        })

        const newTokens = tokens.map((token, id)=>{
            return {
                ...token,
                balance: token.name === "ETH"? formatEther(ethBalance) : formatUnits(balances[id].result as bigint, Number(decimals[id].result)),
                decimals: decimals[id].result?.toString()
            }
        })

        return newTokens
    }
    catch(e){
        return tokens
    }
}


export async function getReserves(tokenA: Token, tokenB: Token){
    const pairAddress = await client.readContract({
        address: factoryAddress,
        abi: factoryABI,
        functionName: "getPair",
        args: [tokenA.address, tokenB.address]
    })

    if(pairAddress === "0x0000000000000000000000000000000000000000"){
        return{
            error: "No Liquidity for trade"
        }
    }

    const reserves: any = await client.readContract({
        address: getAddress(pairAddress as string),
        abi: pairABI,
        functionName: "getReserves"
    })

    const results = await client.multicall({
        contracts: [
            {
                address: getAddress(pairAddress as string),
                abi: pairABI,
                functionName: "token0"
            },
            {
                address: getAddress(pairAddress as string),
                abi: pairABI,
                functionName: "token1"
            }

        ]
    })

    const correctedReserves: bigint[] = [
        results[0].result === tokenA.address? reserves[0] : reserves[1],
        results[1].result === tokenB.address? reserves[1] : reserves[0]
    ]

    return {
        tokenAReserve: correctedReserves[0],
        tokenBReserve: correctedReserves[1],
    }
}



export async function getAmountOut(amountIn: string, tokenIn: Token, tokenOut: Token){
    const decimals = await client.multicall({
        contracts: [
            {
                address: getAddress(tokenIn.address as string),
                abi: erc20Abi,
                functionName: "decimals"
            },
            {
                address: getAddress(tokenOut.address as string),
                abi: erc20Abi,
                functionName: "decimals"
            }

        ]
    })

    const amountInWei = parseUnits(amountIn, Number(decimals[0].result)).toString()

    const{tokenAReserve, tokenBReserve, error} = await getReserves(tokenIn, tokenOut)

    if(error){
        return {
            error: error
        }
    }

    const amountOut: any = await client.readContract({
        address: getAddress(routerAddress as string),
        abi: routerABI,
        functionName: "getAmountOut",
        args: [amountInWei, tokenAReserve, tokenBReserve]
    })

    return {
        amountOut: formatUnits(amountOut as bigint, Number(decimals[1].result))
    }
}

export async function getAmountIn(amountOut: string, tokenIn: Token, tokenOut: Token){
    const decimals = await client.multicall({
        contracts: [
            {
                address: getAddress(tokenIn.address as string),
                abi: erc20Abi,
                functionName: "decimals"
            },
            {
                address: getAddress(tokenOut.address as string),
                abi: erc20Abi,
                functionName: "decimals"
            }

        ]
    })

    const amountOutWei = parseUnits(amountOut, Number(decimals[1].result)).toString()

    const{tokenAReserve, tokenBReserve, error} = await getReserves(tokenIn, tokenOut)

    if(error){
        return {
            error: error
        }
    }

    try{
        const amountIn: any = await client.readContract({
            address: getAddress(routerAddress as string),
            abi: routerABI,
            functionName: "getAmountIn",
            args: [amountOutWei, tokenAReserve, tokenBReserve]
        })

        return {
            amountIn: formatUnits(amountIn as bigint, Number(decimals[0].result))
        }
    }
    catch{
        return {
            error: "No Liquidity for trade"
        }
    }
}

export async function approve(userAddress: string, token: Token){
    const hash = await client.writeContract({
        address: getAddress(token.address),
        abi: erc20Abi,
        functionName: 'approve',
        args: [routerAddress, BigInt(MAX_UINT)],
        account: getAddress(userAddress)
    })

    const receipt = await client.waitForTransactionReceipt({
        hash: hash
    })

    if(receipt.status === "success"){
        return true
    }
    else{
        return false
    }
}

export async function swap(userAddress: string, trade: Trade){

    let swapHash: `0x${string}` | undefined

    const time = Math.floor(Date.now() / 1000) + 200000;
    const deadline = BigInt(time);
    const slippageFormated = Number(trade.slippage).toFixed(1)
    

    const path = [
        trade.tokenIn.address,
        trade.tokenOut.address
    ]

    if(trade.tokenIn.isNative || trade.tokenOut.isNative){

        if(trade.tokenIn.isNative){
            switch(trade.tradeType){
                case "exactIn":
                    console.log("swap exact native to tokens")
                    const parsedExactAmountIn = parseUnits(trade.amountIn, Number(trade.tokenIn.decimals!))
                    const parsedAmountOut = parseUnits(trade.amountOut, Number(trade.tokenOut.decimals))
                    const minAmountOut = parsedAmountOut - (parsedAmountOut / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

                    swapHash = await client.writeContract({
                        address: getAddress(routerAddress),
                        abi: routerABI,
                        functionName: "swapExactETHForTokens",
                        value: parsedExactAmountIn,
                        args: [
                            minAmountOut,
                            path,
                            getAddress(userAddress),
                            deadline
                        ],
                        account: getAddress(userAddress)
                    })
                    break
                case "exactOut":

                    const parsedAmountIn = parseUnits(trade.amountIn, Number(trade.tokenIn.decimals!))
                    const parsedExactAmountOut = parseUnits(trade.amountOut, Number(trade.tokenOut.decimals))
                    const maxAmountIn = parsedAmountIn + (parsedAmountIn / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

                    swapHash = await client.writeContract({
                        address: getAddress(routerAddress),
                        abi: routerABI,
                        functionName: "swapETHForExactTokens",
                        value: maxAmountIn,
                        args: [
                            parsedExactAmountOut,
                            path,
                            getAddress(userAddress),
                            deadline
                        ],
                        account: getAddress(userAddress)
                    })
                    break
            }
        }

        if(trade.tokenOut.isNative){
            switch(trade.tradeType){
                case "exactIn":
                    const parsedExactAmountIn = parseUnits(trade.amountIn, Number(trade.tokenIn.decimals!))
                    const parsedAmountOut = parseUnits(trade.amountOut, Number(trade.tokenOut.decimals))
                    const minAmountOut = parsedAmountOut - (parsedAmountOut / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

                    swapHash = await client.writeContract({
                        address: getAddress(routerAddress),
                        abi: routerABI,
                        functionName: "swapExactTokensForETH",
                        args: [
                            parsedExactAmountIn,
                            minAmountOut,
                            path,
                            getAddress(userAddress),
                            deadline
                        ],
                        account: getAddress(userAddress)
                    })
                    break
                case "exactOut":
                    const parsedAmountIn = parseUnits(trade.amountIn, Number(trade.tokenIn.decimals!))
                    const parsedExactAmountOut = parseUnits(trade.amountOut, Number(trade.tokenOut.decimals))
                    const maxAmountIn = parsedAmountIn + (parsedAmountIn / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

                    swapHash = await client.writeContract({
                        address: getAddress(routerAddress),
                        abi: routerABI,
                        functionName: "swapTokensForExactETH",
                        args: [
                            parsedExactAmountOut,
                            maxAmountIn,
                            path,
                            getAddress(userAddress),
                            deadline
                        ],
                        account: getAddress(userAddress)
                    })
                    break
            }
        }

    }

    else{
        switch(trade.tradeType){
            case "exactIn":
                const parsedExactAmountIn = parseUnits(trade.amountIn, Number(trade.tokenIn.decimals!))
                const parsedAmountOut = parseUnits(trade.amountOut, Number(trade.tokenOut.decimals))
                const minAmountOut = parsedAmountOut - (parsedAmountOut / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

                swapHash = await client.writeContract({
                    address: getAddress(routerAddress),
                    abi: routerABI,
                    functionName: "swapExactTokensForTokens",
                    args: [
                        parsedExactAmountIn,
                        minAmountOut,
                        path,
                        getAddress(userAddress),
                        deadline
                    ],
                    account: getAddress(userAddress)
                })
                break
            case "exactOut":

                const parsedAmountIn = parseUnits(trade.amountIn, Number(trade.tokenIn.decimals!))
                const parsedExactAmountOut = parseUnits(trade.amountOut, Number(trade.tokenOut.decimals))
                const maxAmountIn = parsedAmountIn + (parsedAmountIn / BigInt(1000) * BigInt(Number(slippageFormated) * 10))
                
                swapHash = await client.writeContract({
                    address: getAddress(routerAddress),
                    abi: routerABI,
                    functionName: "swapTokensForExactTokens",
                    args: [
                        parsedExactAmountOut,
                        maxAmountIn,
                        path,
                        getAddress(userAddress),
                        deadline
                    ],
                    account: getAddress(userAddress)
                })
                break
        }
    }

    const receipt = await client.waitForTransactionReceipt({
        hash: swapHash!
    })

    if(receipt.status === "success"){
        return true
    }
    else{
        return false
    }
}

export async function getPoolQuote(tokenA: Token, tokenB: Token, amountA: string){
    const quoteResult: any = await client.readContract({
        address: getAddress(poolsHelperAddress as string),
        abi: poolsHelperABI,
        functionName: "fetchAddLiquidity",
        args: [tokenA.address, tokenB.address, parseUnits(amountA, Number(tokenA.decimals))]
    })

    return {
        fetchedAmountB: quoteResult[0],
        newAmountAinPool: quoteResult[1],
    }
}

export async function getUserPools(userAddress: string){
    const pairsResult: any = await client.readContract({
        address: getAddress(poolsHelperAddress as string),
        abi: poolsHelperABI,
        functionName: "pairUser",
        args: [getAddress(userAddress)]
    })

    const tokensAddresses: string[] = pairsResult[0]
    const tokensNames: string[] = pairsResult[1]
    const pairsDetails: any[] = pairsResult[2]

    const parsedTokens: Token[] = []

    for(let i = 0; i < tokensAddresses.length; i++){
        const token: Token = {
            address: tokensAddresses[i],
            name: tokensNames[i]
        }

        const findInInititalTokens = initialTokens.filter((token)=>{
            return token.address === tokensAddresses[i] && !token.isNative
        })

        if(findInInititalTokens.length !== 0){
            token.icon = findInInititalTokens[0].icon
        }
        else{
            token.icon = "CUSTOM"
        }

        parsedTokens.push(token)
    }

    const sortedPairsDetails: Array<any[]> = []
    const sortedTokens: Array<Token[]> = []

    for(let i = 0; i < pairsDetails.length; i+=6){
        sortedPairsDetails.push(pairsDetails.slice(i, i+6))
    }

    for(let i = 0; i < parsedTokens.length; i+=2){
        sortedTokens.push([parsedTokens[i], parsedTokens[i+1]])
    }

    const callForPairsAddresses = sortedTokens.map((tokens: Token[])=>{
        return{
            address: getAddress(factoryAddress),
            abi: factoryABI as Abi,
            functionName: "getPair",
            args:[getAddress(tokens[0].address), getAddress(tokens[1].address)]
        }
    })

    const pairsAddresses = await client.multicall({
        contracts: [...callForPairsAddresses]
    })

    const pairs: Pair[] = []

    for(let i = 0; i < sortedTokens.length; i++){
        const pairDetails = sortedPairsDetails[i]
        const pairTokens = sortedTokens[i]

        const pair: Pair = {
            tokenA: pairTokens[0],
            tokenB: pairTokens[1],
            pooledAmountA: formatUnits(pairDetails[4], Number(pairDetails[0])),
            pooledAmountB: formatUnits(pairDetails[5], Number(pairDetails[1])),
            userLPAmount: formatUnits(pairDetails[2], 18),
            totalLPAmount: formatUnits(pairDetails[3], 18),
            pairAddress: pairsAddresses[i].result as string
        }

        pairs.push(pair)
    }

    return pairs
}

export async function getEthPrice(){
    const reserves = await getReserves(initialTokens[0], initialTokens[2])

    const price = Number(reserves.tokenBReserve) / Number(reserves.tokenAReserve)
    return price
}

export async function getTokensPrice(tokens: Token[]){
    const ethAddress = "0x4300000000000000000000000000000000000004"
    const nullAddress = "0x0000000000000000000000000000000000000000"

    const ethPrice = await getEthPrice()

    const allFetchTokens = []
    const prices = []

    const callForExistLp = []
    const callForOrders = []
    const callForReserves = []

    for(let token of tokens){
        if(token.address !== ethAddress){
            callForExistLp.push({
                address: getAddress(factoryAddress),
                abi: factoryABI as Abi,
                functionName: "getPair",
                args:[getAddress(token.address), getAddress(ethAddress)]
            })

            allFetchTokens.push({
                tokenAddress: token.address,
                pairAddress: ""
            })
        }
    }

    const pairsAddresses = await client.multicall({
        contracts: [...callForExistLp]
    })

    for(let i = 0; i < pairsAddresses.length; i++){
        allFetchTokens[i].pairAddress = pairsAddresses[i].result
    }

    for(let fetchParams of allFetchTokens){
        if(fetchParams.pairAddress !== nullAddress){
            callForOrders.push(...[
                    {
                        address: getAddress(fetchParams.pairAddress as string),
                        abi: pairABI as Abi,
                        functionName: "token0"
                    },
                    {
                        address: getAddress(fetchParams.pairAddress as string),
                        abi: pairABI as Abi,
                        functionName: "token1"
                    }
                ]
            )

            callForReserves.push(
                {
                    address: getAddress(fetchParams.pairAddress as string),
                    abi: pairABI as Abi,
                    functionName: "getReserves"
                }
            )
        }
    }

    const orderResult = await client.multicall({
        contracts: [...callForOrders]
    })
    const reservesResult = await client.multicall({
        contracts: [...callForReserves]
    })

    const sortedOrderResult = []

    for(let i = 0; i < reservesResult.length; i+=2){
        sortedOrderResult.push([orderResult[i].result, orderResult[i+1].result])
    }

    const notNullPrices = []

    for(let i = 0; i < reservesResult.length; i++){
        if(sortedOrderResult[i][0] !== ethAddress){
            const div = Number(reservesResult[i].result[1]) / Number(reservesResult[i].result[0])
            const price = div * Number(ethPrice)
            notNullPrices.push(price)
        }

        if(sortedOrderResult[i][1] !== ethAddress){
            const div = Number(reservesResult[i].result[0]) / Number(reservesResult[i].result[1])
            const price = div * Number(ethPrice)
            notNullPrices.push(price)
        }
    }

    for(let fetchToken of allFetchTokens){
        let i = 0

        if(fetchToken.pairAddress !== nullAddress){
            prices.push(notNullPrices[i])
            i = i + 1
        }
        else{
            prices.push(0)
        }
    }

    const newTokens = []

    let i = 0

    for(let token of tokens){

        if(token.address !== ethAddress){
            newTokens.push({
                ...token,
                price: prices[i].toString()
            })
            i = i + 1
        }
        else{
            newTokens.push({
                ...token,
                price: ethPrice.toString()
            })
        }
    }

    return newTokens
}

export async function addLiquidity(userAddress: string, tokenA: Token, tokenB: Token, amountA: string, amountB: string, slippage: string){

    let addLiquidityHash: `0x${string}` | undefined

    const time = Math.floor(Date.now() / 1000) + 200000;
    const deadline = BigInt(time);
    const slippageFormated = Number(slippage).toFixed(1)

    if(tokenA.isNative || tokenB.isNative){
        if(tokenA.isNative){
            const parsedAmountA = parseUnits(amountA, Number(tokenA.decimals!))
            const parsedAmountB = parseUnits(amountB, Number(tokenB.decimals!))

            const minAmountA = parsedAmountA - (parsedAmountA / BigInt(1000) * BigInt(Number(slippageFormated) * 10))
            const minAmountB = parsedAmountB - (parsedAmountB / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

            addLiquidityHash = await client.writeContract({
                address: getAddress(routerAddress),
                value: parsedAmountA,
                abi: routerABI,
                functionName: "addLiquidityETH",
                args: [
                    tokenB.address,
                    parsedAmountB,
                    minAmountB,
                    minAmountA,
                    getAddress(userAddress),
                    deadline
                ],
                account: getAddress(userAddress)
            })
        }

        if(tokenB.isNative){
            const parsedAmountA = parseUnits(amountA, Number(tokenA.decimals!))
            const parsedAmountB = parseUnits(amountB, Number(tokenB.decimals!))

            const minAmountA = parsedAmountA - (parsedAmountA / BigInt(1000) * BigInt(Number(slippageFormated) * 10))
            const minAmountB = parsedAmountB - (parsedAmountB / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

            addLiquidityHash = await client.writeContract({
                address: getAddress(routerAddress),
                value: parsedAmountB,
                abi: routerABI,
                functionName: "addLiquidityETH",
                args: [
                    tokenA.address,
                    parsedAmountA,
                    minAmountA,
                    minAmountB,
                    getAddress(userAddress),
                    deadline
                ],
                account: getAddress(userAddress)
            })
        }
    }
    else{
        const parsedAmountA = parseUnits(amountA, Number(tokenA.decimals!))
        const parsedAmountB = parseUnits(amountB, Number(tokenB.decimals!))

        const minAmountA = parsedAmountA - (parsedAmountA / BigInt(1000) * BigInt(Number(slippageFormated) * 10))
        const minAmountB = parsedAmountB - (parsedAmountB / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

        addLiquidityHash = await client.writeContract({
            address: getAddress(routerAddress),
            abi: routerABI,
            functionName: "addLiquidity",
            args: [
                tokenA.address,
                tokenB.address,
                parsedAmountA,
                parsedAmountB,
                minAmountA,
                minAmountB,
                getAddress(userAddress),
                deadline
            ],
            account: getAddress(userAddress)
        })
    }

    const receipt = await client.waitForTransactionReceipt({
        hash: addLiquidityHash!
    })

    if(receipt.status === "success"){
        return true
    }
    else{
        return false
    }
}

export async function removeLiquidity(userAddress: string, pair: Pair, slippage: string){

    let removeLiquidityHash: `0x${string}` | undefined

    const time = Math.floor(Date.now() / 1000) + 200000;
    const deadline = BigInt(time);
    const slippageFormated = Number(slippage).toFixed(1)

    if(pair.tokenA.address === "0x4300000000000000000000000000000000000004" || pair.tokenB.address === "0x4300000000000000000000000000000000000004"){
        if(pair.tokenA.address === "0x4300000000000000000000000000000000000004"){

            const parsedAmountETH = parseUnits(pair.pooledAmountA, 18)
            const parsedAmountToken = parseUnits(pair.pooledAmountB, Number(pair.tokenB.decimals))

            const amountTokenMin = parsedAmountToken - (parsedAmountToken / BigInt(1000) * BigInt(Number(slippageFormated) * 10))
            const amountETHMin = parsedAmountETH - (parsedAmountETH / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

            removeLiquidityHash = await client.writeContract({
                address: getAddress(routerAddress),
                abi: routerABI,
                functionName: Number(slippage) >= 10? "removeLiquidityETHSupportingFeeOnTransferTokens" : "removeLiquidityETH",
                args: [
                    pair.tokenB.address,
                    parseUnits(pair.userLPAmount, 18),
                    amountTokenMin,
                    amountETHMin,
                    getAddress(userAddress),
                    deadline
                ],
                account: getAddress(userAddress)
            })
        }

        if(pair.tokenB.address === "0x4300000000000000000000000000000000000004"){
            const parsedAmountETH = parseUnits(pair.pooledAmountB, 18)
            const parsedAmountToken = parseUnits(pair.pooledAmountA, Number(pair.tokenA.decimals))

            const amountTokenMin = parsedAmountToken - (parsedAmountToken / BigInt(1000) * BigInt(Number(slippageFormated) * 10))
            const amountETHMin = parsedAmountETH - (parsedAmountETH / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

            removeLiquidityHash = await client.writeContract({
                address: getAddress(routerAddress),
                abi: routerABI,
                functionName: Number(slippage) >= 10? "removeLiquidityETHSupportingFeeOnTransferTokens" : "removeLiquidityETH",
                args: [
                    pair.tokenA.address,
                    parseUnits(pair.userLPAmount, 18),
                    amountTokenMin,
                    amountETHMin,
                    getAddress(userAddress),
                    deadline
                ],
                account: getAddress(userAddress)
            })
        }
    }
    else{
        const parsedAmountAToken = parseUnits(pair.pooledAmountA, Number(pair.tokenA.decimals))
        const parsedAmountBToken = parseUnits(pair.pooledAmountB, Number(pair.tokenB.decimals))

        const amountTokenAMin = parsedAmountAToken - (parsedAmountAToken / BigInt(1000) * BigInt(Number(slippageFormated) * 10))
        const amountTokenBMin = parsedAmountBToken - (parsedAmountBToken / BigInt(1000) * BigInt(Number(slippageFormated) * 10))

        removeLiquidityHash = await client.writeContract({
            address: getAddress(routerAddress),
            abi: routerABI,
            functionName: "removeLiquidity",
            args: [
                pair.tokenA.address,
                pair.tokenB.address,
                parseUnits(pair.userLPAmount, 18),
                amountTokenAMin,
                amountTokenBMin,
                getAddress(userAddress),
                deadline
            ],
            account: getAddress(userAddress)
        })
    }

    const receipt = await client.waitForTransactionReceipt({
        hash: removeLiquidityHash!
    })

    if(receipt.status === "success"){
        return true
    }
    else{
        return false
    }
}
import axios from "axios"

export async function getAuthToken(userAddress: string, message: string, signature: string){
    console.log(message)
    console.log(signature)

    const resp = await axios.post("https://blinkswap.xyz/api/v1/login",
        {
            wallet_address: userAddress,
            signature: signature,
            timestamp: message
        }
    )

    return resp.data.data.token as string
}
import dropDownArrow from "../../assets/img/dropdown_arrow.svg"
import tokenIcon from "../../assets/img/token_logo.png"
import { ChoseType, Token, TradeType } from "../../interface/interface"
import { useAccount } from "wagmi"
import { formatBalance } from "../../app/utils/format"
import { formatEther, parseEther } from "viem"
import { cn } from "../../app/utils/cn"
import TokenIcon from "../../shared/TokenIcon"
import { useState } from "react"

interface InputComponentProps{
    modalType: ChoseType
    visibleToken?: Token,
    amount?: string
    label: string,
    openModal(state: React.SetStateAction<boolean>): void,
    setModalType(type: React.SetStateAction<ChoseType>): void,
    setTradeType(type: React.SetStateAction<TradeType>): void,
    setAmount(type: React.SetStateAction<string>): void
}

function SwapInputComponent({amount, modalType, visibleToken, label, openModal, setModalType, setTradeType, setAmount}: InputComponentProps){

    const {isConnected} = useAccount()
    const[isFocus, setIsFocus] = useState(false)

    function handleOpenModal(){

        switch(modalType){
            case "in":
                setModalType("in")
                break
            case "out":
                setModalType("out")
                break
        }

        openModal(true)
    }

    function handleMax(){
        if(modalType === "in" && visibleToken?.balance){

            if(visibleToken.isNative === true){
                const maxEtherAmount = parseEther(visibleToken?.balance) - parseEther("0.000001")
                setAmount(formatEther(maxEtherAmount))
            }
            else{
                setAmount(visibleToken.balance)
            }
        }
    }

    function handleAmountChange(event: React.ChangeEvent<HTMLInputElement>){
        const formatedValue = event.target.value.replace(/[^0-9.,]/g, '').replace(",", ".").replace( /^([^\.]*\.)|\./g, '$1' )
        setAmount(formatedValue)
        switch(modalType){
            case "in":
                setTradeType("exactIn")
                break
            case "out":
                setTradeType("exactOut")
                break
        }
    }

    return(
        <div className={cn("w-full border border-grey px-5 py-3 flex flex-col bg-black bg-opacity-60", {"border-white": isFocus})}>
            <div className="flex justify-between items-center mb-3">
                <div className="text-grey text-xs uppercase">
                    {label}
                </div>
                {isConnected&&
                    <div className={cn("text-grey uppercase text-xs", {"cursor-pointer": modalType === "in"})} onClick={handleMax}>
                        {`Balance: ${visibleToken?.balance? formatBalance(visibleToken.balance) : "0"}`}
                    </div>
                }
            </div>
            <div className="flex justify-between gap-1 w-full mb-1">
                <input 
                    value={amount? formatBalance(amount) : ""}
                    type="text"
                    maxLength={visibleToken? Number(visibleToken.decimals) + 1 : 19}
                    placeholder="0.0"
                    className="bg-opacity-0 bg-black border-none outline-none text-white placeholder:text-grey flex w-0 flex-1 text-lg"
                    onChange={(e)=>handleAmountChange(e)}
                    onFocus={()=>{setIsFocus(true)}}
                    onBlur={()=>{setIsFocus(false)}}
                />
                <div className="flex items-center gap-2 cursor-pointer border border-grey px-3 py-1 hover:bg-darkGrey text-sm 2xl:text-base" onClick={handleOpenModal}>
                    <div className="flex gap-2 items-center w-full">
                        {visibleToken?
                            <div className="flex gap-2 items-center">
                                <TokenIcon icon={visibleToken.icon!} className="size-5"/>
                                <div className="text-white uppercase">
                                    {visibleToken.name}
                                </div>
                            </div>
                            :
                            <div className="flex py-1 text-white text-nowrap text-xs uppercase">
                                Select Token
                            </div>
                        }
                    </div>
                    <img src={dropDownArrow} alt="" className="size-3"/>
                </div>
            </div>
            <div className=" text-xs text-grey">
                ~${visibleToken? visibleToken.price? (Number(visibleToken.price) * Number(amount)).toFixed(2) : "-.--" :  "-.--"}
            </div>
        </div>
    )
}

export default SwapInputComponent
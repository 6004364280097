import socialPageBg from "../../../assets/img/social_page_bg.svg"
import SocialQuestTasksComponent from "./SocialQuestTasksComponent"
import spotlight from "../../../assets/img/spotlight.png"
import { useContext, useState } from "react"
import AuthPage from "../../../shared/AuthPage"
import ConnectRequirePage from "../../../shared/ConnectRequirePage"
import { useAccount } from "wagmi"
import { AppContext } from "../../../app/app.context"
import OnlyDesktop from "../../../shared/OnlyDesktop"
import { findAuthToken } from "../../../app/utils/findAuthToken"

function SocialQuestPage(){
    const{ address, isConnected, chainId } = useAccount()
    const[authToken, setAuthToken] = useState(findAuthToken(address?.toString()))

    const context = useContext(AppContext)

    if(!isConnected){
        return <ConnectRequirePage/>
    }

    if(!context.authToken){
        return(
            <AuthPage/>
        )
    }

    if(context.width < 700){
        return(
            <OnlyDesktop/>
        )
    }

    return(
        <div className="w-full flex justify-center grow">
            <div className="w-[90%] flex flex-col my-[4%]">
                <div className="relative w-full">
                    <img src={spotlight} alt="" className="w-full absolute translate-x-[80%] translate-y-[-32%]"/>
                </div>
                <div className="relative w-full">
                    <img src={socialPageBg} alt="" className="w-1/2 absolute opacity-10 translate-x-[110%]"/>
                </div>
                <div className="flex flex-col gap-4 2xl:gap-8 z-30">
                    <div className="font-variable text-4xl text-white uppercase font-medium">
                        Social Quest
                    </div>
                    <div className="text-grey leading-5">
                        Сomplete tasks to get <br/>Blink Points
                    </div>
                </div>
                <SocialQuestTasksComponent/>
                <div className="relative w-full">
                    <img src={spotlight} alt="" className="w-full absolute translate-y-[-25%]"/>
                </div>
            </div>
        </div>
    )
}

export default SocialQuestPage
import { UserPoints } from "../../interface/interface"
import PositionDrawer from "./PositionDrawer"

const positions = Array.from(Array(100), (_, i) => i+1)

interface UserPosition{
    address: string,
    points: string
}

interface LeaderboardInterface{
    social: UserPosition[],
    swap: UserPosition[],
    lp: UserPosition[]
}

interface LeaderboardComponentProps{
    leaderboard: LeaderboardInterface,
    userPoints?: UserPoints
}

function formatPoints(points: string){
    if(Number(points) === 0){
        return "0"
    }
    if(Number(points) <= 0.001){
        return "< 0.001"
    }
    else{
        return Number(points).toFixed(3)
    }
}

function LeaderboardComponent({leaderboard, userPoints}: LeaderboardComponentProps){
    return(
        <div className="w-full grid grid-cols-[1fr_repeat(4,3fr)] grid-rows-[80px_auto] 2xl:grid-rows-[100px_auto]  gap-[1px]">
            <div className="outline-1 outline-darkGrey outline"></div>
            <div className="outline-1 outline-darkGrey outline flex justify-center text-lg">
                <div className="w-full flex flex-col items-center justify-center gap-1 ">
                    <div className="">
                        Social Points
                    </div>
                    <div className="w-1/2 bg-black text-white flex justify-center items-center text-lg border border-white">
                        {userPoints? formatPoints(userPoints.socialPoints) : "-"}
                    </div>
                </div>
            </div>
            <div className="outline-1 outline-darkGrey outline flex justify-center text-lg">
                <div className="w-full flex flex-col items-center justify-center gap-1">
                    <div className="">
                        Swap Points
                    </div>
                    <div className="w-1/2 bg-black text-white flex justify-center items-center text-lg border border-white">
                        {userPoints? formatPoints(userPoints.swapPoints) : "-"}
                    </div>
                </div>
            </div>
            <div className="outline-1 outline-darkGrey outline flex justify-center text-lg">
                <div className="w-full flex flex-col items-center justify-center gap-1">
                    <div className="">
                        LP Points
                    </div>
                    <div className="w-1/2 bg-black text-white flex justify-center items-center text-lg border border-white">
                        {userPoints? formatPoints(userPoints.lpPoints) : "-"}
                    </div>
                </div>
            </div>
            <div className="outline-1 outline-darkGrey outline flex justify-center text-lg">
                <div className="w-full flex flex-col items-center justify-center gap-1">
                    <div className="">
                        Contributor points
                    </div>
                    <div className="w-1/2 bg-black text-white flex justify-center items-center text-lg border border-white">
                        0
                    </div>
                </div>
            </div>
            <div className="outline-1 outline-darkGrey outline flex flex-col gap-[1px] text-white text-sm 2xl:text-base">
                <div className="outline-1 outline-darkGrey outline uppercase h-12 flex justify-center items-center text-grey">
                    Position
                </div>
                {positions.map((position)=>{
                    return(
                        <div className="outline-1 outline-darkGrey outline h-12 w-full flex justify-center items-center">
                            {position}
                        </div>
                    )
                })}
            </div>
            <div className="outline-1 outline-darkGrey outline">
                <PositionDrawer positions={leaderboard.social}/>
            </div>
            <div className="outline-1 outline-darkGrey outline">
                <PositionDrawer positions={leaderboard.swap}/>
            </div>
            <div className="outline-1 outline-darkGrey outline">
                <PositionDrawer positions={leaderboard.lp}/>
            </div>
            <div className="outline-1 outline-darkGrey outline">
                <PositionDrawer positions={[]}/>
            </div>
        </div>
    )
}

export default LeaderboardComponent
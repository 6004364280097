import { ConnectRainbowButton } from "./ConnectRainbowButton";
import swapBg from "../assets/img/swap_page_bg.svg"

function ConnectRequirePage(){
    return(
        <div className="w-full flex flex-col grow items-center justify-center">
            {/* <div className="w-full relative">
                <img src={swapBg} alt="" className="w-[120%] h-full fixed top-0 left-0 object-cover"/>
            </div> */}
            <div className="z-30 text-grey mb-3">
                To use app connect your wallet
            </div>
            <div className="z-30">
                <ConnectRainbowButton/>
            </div>
        </div>
    )
}

export default ConnectRequirePage
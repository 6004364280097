import closeButton from "../../assets/img/close_button.svg"
import DefaultButton from "../../shared/DefaultButton"

interface ImpactModalProps{
    onClose(state: React.SetStateAction<boolean>): void
    handleSwap(allowImpact: boolean): void
}

function ImpactModal({onClose, handleSwap}: ImpactModalProps){
    function swapAnyway(){
        handleSwap(true)
        onClose(false)
    }

    return(
        <div className="fixed flex  justify-center bg-black bg-opacity-90 top-0 left-0 w-full h-full overflow-hidden z-40" onClick={()=>onClose(false)}>
            <div className="flex flex-col bg-black border border-white w-[31%] 2xl:w-[25%] px-5 py-5 gap-7 min-w-[335px] h-fit my-52" onClick={(e)=> e.stopPropagation()}>
                <div className="flex justify-between items-center">
                    <div className="text-white text-lg">
                        PRICE IMPACT IS VERY HIGH
                    </div>
                    <img src={closeButton} alt="" className="size-4 cursor-pointer opacity-100" onClick={()=>onClose(false)}/>
                </div>
                <div className="text-grey text-sm text-justify">
                    There may be a large difference between the amount of your input token and what you will receive in the output token
                </div>
                <div className="flex justify-center">
                    <div className="w-1/2" onClick={swapAnyway}>
                        <DefaultButton className="text-base">
                            Swap Anyway
                        </DefaultButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImpactModal
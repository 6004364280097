export function formatBalance(balance: string){
    const match = balance.match(/^([+-]?\d*\.\d*?)(0*)(\d{0,3})/);

    if (match) {
        const [, integerPart, zeros, significantDigits] = match;
        return integerPart + zeros + significantDigits;
    }

    // Если входное значение не является дробным числом
    return balance;
}

export function formatRatio(balance: string){
    if(isNaN(Number(balance))){
        return "-"
    }
    if(Number(balance) < 0.000001){
        return "< 0.000001"
    }
    if(balance === "0"){
        return "0.0"
    }
    
    const separatedBalance = balance.split(".")

    if(Number(separatedBalance[0]) === 0 && separatedBalance[1]){
        const valueDecimalsPart = Number(separatedBalance[1]).toString()
        const firstNotNull = valueDecimalsPart.slice(0, 1)
        const zeroes = separatedBalance[1].split(firstNotNull)[0]
        const formatedBalance = separatedBalance[0] + "." + zeroes + valueDecimalsPart.slice(0, 4)
        return formatedBalance
    }
    if(Number(separatedBalance[0]) !== 0 && !separatedBalance[1]){
        return balance
    }
    else{
        return Number(balance).toFixed(3)
    }
}
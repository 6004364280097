import eth_logo from "../assets/img/eth_logo.svg"
import weth_logo from "../assets/img/weth_logo.svg"
import usdb_logo from "../assets/img/usdb_logo.svg"
import wbtc_logo from "../assets/img/wbtc_logo.svg"
import custom_logo from "../assets/img/custom_logo.svg"

type TokenIconName = "ETH" | "WETH" | "USDB" | "CUSTOM"

const tokensIcon: Record<string, string> = {
    "ETH": eth_logo,
    "WETH": weth_logo,
    "USDB": usdb_logo,
    "WBTC": wbtc_logo,
    "CUSTOM": custom_logo
}

interface TokenIconProps{
    className: string,
    icon: string
}

function TokenIcon({icon, className}: TokenIconProps){
    return(
        <img src={tokensIcon[icon]} alt="" className={className}/>
    )
}

export default TokenIcon
import { useAccount } from "wagmi"
import { getUserSignature } from "../app/ethereumFunctions"
import DefaultButton from "./DefaultButton"
import { getAuthToken } from "../app/api/authApi"
import { useContext } from "react"
import { AppContext } from "../app/app.context"

interface AuthPageProps{
    setAuthToken(authToken: React.SetStateAction<string | null>): void
}

function AuthPage(){
    const{ address } = useAccount()

    const context = useContext(AppContext)

    function getUserAuthToken(){
        const timestamp = Date.now().toString()
        if(address !== undefined){
            getUserSignature(address!, timestamp)
            .then((signature: string)=>{
                getAuthToken(address!, timestamp, signature)
                .then((token: string)=>{
                    const tokenObject: { [key: string]: string } = {};
                    tokenObject[address] = token
                    console.log(tokenObject)

                    window.localStorage.setItem("authToken", JSON.stringify(tokenObject))
                    context.setAuthToken?.(token)
                })
            })
        }
    }

    return(
        <div className="w-full flex flex-col grow items-center justify-center">
            <div className="z-30 text-grey mb-3">
                To continue authorize your wallet
            </div>
            <div className="z-30 w-fit" onClick={getUserAuthToken}>
                <DefaultButton className="text-sm px-5 py-2">
                    Sign Message
                </DefaultButton>
            </div>
        </div>
    )
}

export default AuthPage
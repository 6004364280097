import { ReactNode } from "react"
import { cn } from "../app/utils/cn"

interface DefaultButtonProps{
    children: ReactNode,
    className?: string,
}

function DefaultButton({children, className,}: DefaultButtonProps){
    return(
        <div className={cn(`w-full border border-white whitespace-nowrap flex justify-center font-variable text-white text-xl p-[2.5%] bg-black uppercase duration-200  hover:bg-white hover:text-black cursor-pointer`, className)}>
            {children}
        </div>
    )
}

export default DefaultButton
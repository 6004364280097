function OnlyDesktop(){
    return(
        <div className="w-full flex flex-col grow items-center justify-center">
            <div className="z-30 text-grey text-sm px-5 text-center">
                Only the desktop version is available at the moment
            </div>
        </div>
    )
}

export default OnlyDesktop
import { useContext, useEffect, useState } from "react"
import LeaderboardComponent from "./LeaderboardComponent"
import socialPageBg from "../../assets/img/social_page_bg.svg"
import spotlight from "../../assets/img/spotlight.png"
import { getLeaderboard, getUserPoints } from "../../app/api/leaderboardApi"
import { UserPoints } from "../../interface/interface"
import { AppContext } from "../../app/app.context"
import OnlyDesktop from "../../shared/OnlyDesktop"
import { useAccount } from "wagmi"
import { findAuthToken } from "../../app/utils/findAuthToken"
import AuthPage from "../../shared/AuthPage"
import ConnectRequirePage from "../../shared/ConnectRequirePage"

interface UserPosition{
    address: string,
    points: string
}

interface LeaderboardInterface{
    social: UserPosition[],
    swap: UserPosition[],
    lp: UserPosition[]
}

function LeaderboardPage(){
    const{ address, isConnected, chainId } = useAccount()

    const[leaderboard, setLeaderboard] = useState<LeaderboardInterface | undefined>()
    const[userPoints, setUserPoints] = useState<UserPoints | undefined>(undefined)

    const context = useContext(AppContext)

    useEffect(()=>{
        getLeaderboard()
        .then((leaderboard)=>{
            setLeaderboard(leaderboard)
        })
        if(context.authToken){
            console.log(context.authToken)
            getUserPoints(context.authToken!)
            .then((fetchedUserPoints: UserPoints)=>{
                setUserPoints(fetchedUserPoints)
            })
        }
    }, [address, context.authToken])

    if(!isConnected){
        return <ConnectRequirePage/>
    }

    if(!context.authToken){
        return(
            <AuthPage/>
        )
    }

    if(context.width < 900){
        return(
            <OnlyDesktop/>
        )
    }

    return(
        <div className="w-full flex justify-center grow my-[4%] text-white">
            <div className="w-[85%] flex flex-col">
                <div className="relative w-full">
                    <img src={spotlight} alt="" className="fixed w-[85%] 2xl:w-auto translate-x-[70%] translate-y-[-32%]"/>
                </div>
                <div className="relative w-full">
                    <img src={socialPageBg} alt="" className="fixed w-[40%] 2xl:w-auto opacity-10 translate-x-[110%]"/>
                </div>
                <div className="uppercase font-semibold text-5xl mb-[5%] z-30">
                    Blink Points
                </div>
                <div className="z-30">
                    {leaderboard&&
                        <LeaderboardComponent leaderboard={leaderboard} userPoints={userPoints}/>
                    }
                </div>
                <div className="relative w-full">
                    <img src={spotlight} alt="" className="w-full absolute translate-y-[-25%]"/>
                </div>
            </div>
        </div>
    )
}

export default LeaderboardPage
import axios from "axios"


export async function submitReferralCode(code: string, token: string){
    try{
        const resp = await axios.post("https://blinkswap.xyz/api/v1/verify-code",
        {
            referral_code: code
        },
        {
            headers: {
                'Authorization': `token ${token}`
            }
        }
        )
    }
    catch{
        throw new Error()
    }
}

export async function getReferralCode(token: string){
    const resp = await axios.get("https://blinkswap.xyz/api/v1/get-code",
        {
            headers: {
                'Authorization': `token ${token}`
            }
        }
    )
}
import { ReactNode } from "react"

interface DefaultButtonProps{
    children: ReactNode,
    color?: string,
}

function ScobedText({children, color}: DefaultButtonProps){
    return(
        <div className="flex">
            <div className={`w-2 border-2 border-${color? color : "white"} border-r-0`}>

            </div>
            {children}
            <div className={`w-2 border-2 border-${color? color : "white"} border-l-0`}>

            </div>
        </div>
    )
}

export default ScobedText
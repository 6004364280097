interface TVLProgressComponentProps{
    tvl: string
}

function TVLProgressComponent({tvl}: TVLProgressComponentProps){

    function tvlShare(){
        const share = (Number(tvl) / 100000) * 100
        console.log(share.toFixed(0))
        return share.toFixed(0)
    }

    const sharePercentage = `${tvlShare()}%`

    return(
        <div className="flex flex-col w-full 2xl:text-lg text-sm">
            <div className="flex w-full">
                <div className="flex flex-col w-full">
                    <div>
                        <div style={{ width: sharePercentage }} className="h-[9px] bg-grey"></div>
                        <div className="w-full h-[1px] bg-white"></div>
                    </div>
                    <div className="flex mt-[-10px] justify-between">
                        <div className="h-[20px] w-0 bg-white"></div>
                        <div className="flex flex-col gap-3">
                            <div className="h-[20px] w-[1px] bg-white"></div>
                            <div className="text-white absolute mt-8">
                                $25K
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="h-[20px] w-[1px] bg-white"></div>
                            <div className="text-white absolute mt-8">
                                $50K
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="h-[20px] w-[1px] bg-white"></div>
                            <div className="text-white absolute mt-8">
                                $75K
                            </div>
                        </div>
                        <div className="h-[20px] w-0 bg-white"></div>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div className="h-[20px] w-[1px] bg-white"></div>
                    <div className="text-white">
                        $100K
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default TVLProgressComponent
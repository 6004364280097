import { useEffect, useState } from "react"
import closeButton from "../../assets/img/close_button.svg"
import searchIcon from "../../assets/img/search_icon.svg"
import tokenIcon from "../../assets/img/token_logo.png"
import { Token, ChoseType } from "../../interface/interface"
import { isAddress } from "viem"
import { useAccount } from "wagmi"
import { getToken } from "../../app/ethereumFunctions"
import { formatBalance } from "../../app/utils/format"
import TokenIcon from "../../shared/TokenIcon"


interface TokensModalProps{
    type: ChoseType
    tokenIn: Token | undefined,
    tokenOut: Token | undefined,
    tokens: Token[]
    onClose(state: React.SetStateAction<boolean>): void
    setTokenIn(token: React.SetStateAction<Token | undefined>): void
    setTokenOut(token: React.SetStateAction<Token | undefined>): void
    setTokens(token: React.SetStateAction<Token[]>): void
}

function TokensModal({tokenIn, tokenOut, type, tokens, onClose, setTokenIn, setTokenOut, setTokens}: TokensModalProps){
    const { address } = useAccount()

    const[tokensSeacrh, setTokensSeacrh] = useState<string>('')
    const[filteredTokens, setFilteredTokens] = useState(tokens)
    const[customToken, setCustomToken] = useState<Token>()


    function findNewToken(tokenAddress: string){
        getToken(tokenAddress, address&& address)
        .then(({token, error})=>{
            if(!error){
                setCustomToken(token)
            }
        })
    }

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>){
        setTokensSeacrh(event.target.value)
    }

    function handleChose(token: Token, isCustom?: boolean){
        switch(type){
            case "in":
                if(token === tokenOut){
                    setTokenIn(tokenOut)
                    setTokenOut(tokenIn)
                }
                else{
                    setTokenIn(token)
                }
                break
            case "out":
                if(token === tokenIn){
                    setTokenIn(tokenOut)
                    setTokenOut(tokenIn)
                }
                else{
                    setTokenOut(token)
                }
                break
        }



        if(isCustom){
            token.icon = "CUSTOM"
            setTokens(
                [
                    ...tokens,
                    token
                ]
            )
        }

        onClose(false)
    }

    useEffect(()=>{
        setFilteredTokens(tokens.filter((token)=>{
            return (
                token.name.toLowerCase().includes(tokensSeacrh.toLowerCase()) || token.address.toLowerCase().includes(tokensSeacrh.toLowerCase())
            )
        }))

        if(isAddress(tokensSeacrh) && filteredTokens.length === 0){
            findNewToken(tokensSeacrh)
        }

    }, [tokensSeacrh, tokens, type])

    useEffect(()=>{
        if(isAddress(tokensSeacrh) && filteredTokens.length === 0){
            findNewToken(tokensSeacrh)
        }
    }, [tokensSeacrh, filteredTokens])

    return(
        <div className="fixed flex items-center justify-center bg-black bg-opacity-50 top-0 left-0 w-full h-full overflow-hidden z-40" onClick={()=>onClose(false)}>
            <div className="flex flex-col bg-black border border-white w-[32%] 2xl:w-[26%] h-2/3 px-4 py-5 gap-5 min-w-[335px]" onClick={(e)=> e.stopPropagation()}>
                <div className="flex justify-between items-center">
                    <div className="text-white text-lg">
                        SELECT TOKEN
                    </div>
                    <img src={closeButton} alt="" className="size-4 cursor-pointer opacity-100" onClick={()=>onClose(false)}/>
                </div>
                <div className="flex items-center border border-white py-2 px-4 gap-3 mb-4">
                    <img src={searchIcon} alt="" className="size-4"/>
                    <input 
                        type="text" 
                        placeholder="Search tokens or input address" 
                        lang="en_EN" 
                        className="bg-opacity-0 bg-black border-none outline-none text-white placeholder:text-grey w-full"
                        onChange={(e)=> handleOnChange(e)}
                    />
                </div>
                {
                    filteredTokens.length !== 0?
                        <div className="flex flex-col w-full overflow-y-auto mb-3 grow cursor-pointer">
                            {filteredTokens.map((token)=>{
                                return(
                                    <div className="w-full flex justify-between px-5 py-2 duration-150 hover:bg-darkBlack" onClick={()=>handleChose(token)}>
                                        <div className="absolute bg-black opacity-50"></div>
                                        <div className="flex gap-4 items-center">
                                            <TokenIcon icon={token.icon!} className="size-6"/>
                                            <div className="text-white uppercase">
                                                {token.name}
                                            </div>
                                        </div>
                                        <div className="text-white">
                                            {token.balance? formatBalance(token.balance) : "0.0"}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className="flex flex-col w-full overflow-y-auto mb-3 grow cursor-pointer">
                            {
                                customToken?
                                    <div className="w-full flex justify-between px-5 py-2 duration-150 hover:bg-darkBlack" onClick={()=>handleChose(customToken, true)}>
                                        <div className="absolute bg-black opacity-50"></div>
                                        <div className="flex gap-4 items-center">
                                            <TokenIcon icon="CUSTOM" className="size-6"/>
                                            <div className="text-white uppercase">
                                                {customToken.name}
                                            </div>
                                        </div>
                                        <div className="text-white">
                                            {customToken.balance? formatBalance(customToken.balance) : "0.0"}
                                        </div>
                                    </div>
                                    :
                                    <div className="flex justify-center items-center text-white grow">
                                        No tokens found
                                    </div>
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default TokensModal
import { Link, useSearchParams } from "react-router-dom"
import swapBg from "../../assets/img/swap_page_bg.svg"
import DefaultButton from "../../shared/DefaultButton"
import { useEffect, useState } from "react"
import AddLiquidityPage from "../AddLiquidity/AddLiquidityPage"
import PoolInfoComponent from "./PoolInfoComponent"
import { initialTokens } from "../../app/constants"
import { Pair, Token } from "../../interface/interface"
import { approve, checkApprove, getPairAddress, getUserPools, removeLiquidity } from "../../app/ethereumFunctions"
import { useAccount } from "wagmi"
import ConnectRequirePage from "../../shared/ConnectRequirePage"
import SwitchChainRequire from "../../shared/SwitchChainRequire"

function PoolPage(){
    const { address, isConnected, chainId } = useAccount()

    const[isAddLiquidityActive, setIsAddLiquidityActive] = useState(false)
    const[userPairs, setUserPairs] = useState<Pair[]>([])

    const[slippage, setSlippage] = useState<string | undefined>("0.5")

    const[addPair, setAddPair] = useState<Token[] | undefined>()

    function handleRemovePair(pair: Pair){

        const pairToken: Token = {
            name: pair.tokenA.name + "/" + pair.tokenB.name,
            address: pair.pairAddress,
            decimals: "18"
        }

        checkApprove(address!.toString(), pairToken, pair.userLPAmount)
        .then((isApproved: boolean)=>{

            if(!isApproved){
                approve(address!.toString(), pairToken)
                .then((status: boolean)=>{
                    if(status === true){
                        removeLiquidity(address!.toString(), pair, slippage!)
                        .then(()=>{
                            getUserPools(address!.toString())
                            .then((pairs: Pair[])=>{
                                setUserPairs(pairs)
                            })
                        })
                    }
                })
            }
            else{
                removeLiquidity(address!.toString(), pair, slippage!)
                .then(()=>{
                    getUserPools(address!.toString())
                    .then((pairs: Pair[])=>{
                        setUserPairs(pairs)
                    })
                })
            }

        })
    }

    useEffect(()=>{
        if(chainId === 81457){
            getUserPools(address!.toString())
            .then((pairs: Pair[])=>{
                setUserPairs(pairs)
            })
        }
    }, [])

    useEffect(()=>{
        const Interval = setInterval(()=>{
            if(chainId === 81457){
                getUserPools(address!.toString())
                .then((pairs: Pair[])=>{
                    setUserPairs(pairs)
                })
            }
        }, 10000)

        return()=>{
            clearInterval(Interval)
        }
    }, [])

    if(!isConnected){
        return <ConnectRequirePage/>
    }

    return(
        <div className="w-full flex flex-col grow items-center tracking-wider">
            {
                chainId !== 81457&&
                    <div className="fixed flex items-center justify-center bg-black bg-opacity-50 top-0 left-0 w-full h-full overflow-hidden z-40 backdrop-blur-sm">
                        <SwitchChainRequire/>
                    </div>
            }
            <div className="w-full relative">
                <img src={swapBg} alt="" className="w-[120%] h-full fixed top-0 left-0 object-cover"/>
            </div>
            {isAddLiquidityActive?
                <AddLiquidityPage onClose={setIsAddLiquidityActive} initialPair={addPair} slippage={slippage} setSlippage={setSlippage}/>
                :
                <div className="w-1/3 flex flex-col z-30 gap-8 my-14 2xl:my-20 max-w-[430px] min-w-[350px]">
                    <div className="flex flex-col gap-4">
                        <div className="flex w-full gap-4 z-30">
                            <div className="w-full">
                                <Link to="/">
                                    <DefaultButton className="text-sm py-2">
                                        Swap
                                    </DefaultButton>
                                </Link>
                            </div>
                            <div className="w-full">
                                <DefaultButton className="text-sm text-black py-2 bg-white">
                                    Pool
                                </DefaultButton>
                            </div>

                        </div>
                        <div className="w-full" onClick={()=>{setIsAddLiquidityActive(true)}}>
                            <DefaultButton className="text-sm py-2">
                                Add Liquidity
                            </DefaultButton>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="text-white px-5 text-lg">
                            Your Liquidity
                        </div>
                        <div className="w-full border border-grey flex justify-center items-center text-grey p-5 flex-col gap-5">
                            {
                                userPairs.length !== 0?
                                    userPairs.map((pair: Pair)=>{
                                        return(
                                            <PoolInfoComponent pair={pair} onRemove={handleRemovePair} onClose={setIsAddLiquidityActive} setPair={setAddPair}/>
                                        )
                                    })
                                :
                                "You dont have any LP"
                            }
                        </div>
                    </div>
                    <div className="flex flex-col items-center text-white text-sm">
                        <div>
                            Don’t see a pool you joined?
                        </div>
                        <div className="underline cursor-pointer">
                            Import it.
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PoolPage
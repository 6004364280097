import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../../app/app.context"
import socialPageBg from "../../../assets/img/social_page_bg.svg"
import spotlight from "../../../assets/img/spotlight.png"
import DefaultButton from "../../../shared/DefaultButton"
import OnlyDesktop from "../../../shared/OnlyDesktop"
import TokenIcon from "../../../shared/TokenIcon"
import TVLProgressComponent from "./TVLProgressComponent"
import ScobedText from "../../../shared/ScobedText"
import { useNavigate } from "react-router-dom"
import { getMetrcis } from "../../../app/api/metricsApi"
import { getUserPoints } from "../../../app/api/leaderboardApi"
import { getUserInfo } from "../../../app/api/infoApi"
import { Pair, UserTask } from "../../../interface/interface"
import { getEthPrice, getUserPools } from "../../../app/ethereumFunctions"
import { useAccount } from "wagmi"
import { getPrice } from "../../../app/api/priceApi"
import { findAllETH } from "../../../app/utils/findAllETH"
import ConnectRequirePage from "../../../shared/ConnectRequirePage"
import AuthPage from "../../../shared/AuthPage"

function LiquidityQuestPage(){
    const { address, isConnected, chainId } = useAccount()
    
    const[currentTvl, setTvl] = useState("0")
    const[currentBoost, setCurrentBoost] = useState("0")
    const[lpPoints, setLpPoints] = useState("0")
    const[userBoost, setUserBoost] = useState("0")
    const[userPairs, setUserPairs] = useState<Pair[]>([])

    const[userLpValue, setUserLpValue] = useState("0")

    const context = useContext(AppContext)
    const navigate = useNavigate()

    useEffect(()=>{
        getMetrcis(context.authToken!)
        .then(({tvl, boost})=>{
            setTvl(tvl)
            setCurrentBoost(boost)
        })
        getUserPoints(context.authToken!)
        .then(({lpPoints})=>{
            setLpPoints(lpPoints)
        })
        getUserInfo(context.authToken!)
        .then((user: UserTask)=>{
            setUserBoost(user.boost)
        })
        getUserPools(address!.toString())
        .then((pairs: Pair[])=>{
            const findedETH = findAllETH(pairs)
            getEthPrice()
            .then((price: number)=>{
                const lpValue = (price * Number(findedETH) * 2).toFixed(2)
                setUserLpValue(lpValue)
            })
        })
    }, [])

    if(!isConnected){
        return <ConnectRequirePage/>
    }

    if(!context.authToken){
        return(
            <AuthPage/>
        )
    }

    if(context.width < 1050){
        return(
            <OnlyDesktop/>
        )
    }

    console.log(userPairs)

    return(
        <div className="w-full flex justify-center grow">
            <div className="w-[90%] flex flex-col my-[4%]">
                <div className="relative w-full">
                    <img src={spotlight} alt="" className="w-full absolute translate-x-[80%] translate-y-[-32%]"/>
                </div>
                <div className="relative w-full">
                    <img src={socialPageBg} alt="" className="w-1/2 absolute opacity-10 translate-x-[110%]"/>
                </div>
                <div className="text-4xl text-white uppercase font-medium mb-10">
                    Blink Tvl
                </div>
                <div className="border border-grey border-b-0 w-fit text-white text-base px-8 py-2">
                    Your Points
                </div>
                <div className="flex gap-10 text-white z-30 mb-10">
                    <div className="flex w-full border border-grey py-3 px-8 bg-[url(/src/assets/img/swap_quest_bg.svg)] bg-center bg-cover 2xl:text-lg text-sm">
                        <div className="flex items-center justify-between w-full gap-10">
                            <div className="flex flex-col gap-3 items-center w-full">
                               <div className="uppercase">
                                    Lp Value
                               </div>
                               <div className="border border-white w-full py-1 2xl:py-2  flex justify-center bg-black">
                                    ${userLpValue}
                               </div>
                            </div>
                            <div className="flex flex-col gap-3 items-center w-full">
                               <div className="uppercase">
                                    Lp Points
                               </div>
                               <div className="border border-white w-full py-1 2xl:py-2 flex justify-center bg-black">
                                    {Number(lpPoints).toFixed(3)}
                               </div>
                            </div>
                            <div className="flex flex-col gap-3 items-center w-full">
                               <div className="uppercase">
                                    Boost
                               </div>
                               <div className="border border-white w-full py-1 2xl:py-2 flex justify-center bg-black">
                                    {userBoost}x
                               </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full border border-grey bg-black  items-center py-5 px-8 gap-6 2xl:gap-9 bg-[url(/src/assets/img/swap_quest_bg.svg)] bg-center bg-cover">
                        <div className="flex w-4/5 2xl:text-lg text-sm">
                            <div className="flex flex-col gap-2 w-full items-center">
                                <div className="uppercase">
                                    Current Boost
                                </div>
                                <div className="border border-white py-1 2xl:py-2 w-2/3 flex justify-center bg-black">
                                    {currentBoost}x
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 w-full items-center">
                                <div className="uppercase">
                                    Current TVL
                                </div>
                                <div className="border border-white py-1 2xl:py-2 w-2/3 flex justify-center bg-black">
                                    ${(Number(currentTvl) / 1000).toFixed(1)}K
                                </div>
                            </div>
                        </div>
                        <TVLProgressComponent tvl={currentTvl}/>
                    </div>
                </div>
                <div className="flex w-1/2 pr-5 gap-5 items-center">
                    <div className="uppercase text-lg text-white text-nowrap">
                        Suggested Pool:
                    </div>
                    <div className="flex gap-3 2xl:gap-5 w-full">
                        <div className="border border-grey py-1 2xl:py-2 w-4/5 px-3">
                            <div className="flex justify-between items-center px-1">
                                <div className="flex justify-between items-center w-full">
                                    <div className="flex gap-2 items-center">
                                        <div className="flex gap-1">
                                            <TokenIcon icon={"USDB"} className="2xl:size-[24px] size-[20px]"/>
                                            <TokenIcon icon={"WETH"} className="2xl:size-[24px] size-[20px]"/>
                                        </div>
                                        <div className="text-white text-sm font-thin 2xl:text-base">
                                            USDB/WETH
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/5" onClick={()=>{navigate("/pool")}}>
                            <DefaultButton className="py-1 2xl:py-2 text-sm 2xl:text-base">
                                ADD
                            </DefaultButton>
                        </div>
                    </div>
                </div>
                <div className="w-full text-grey grow flex flex-col mt-10">
                    <div className="flex my-auto">
                        <ScobedText>
                            <div className="text-justify px-5 text-sm 2xl:text-lg">
                                Complete all  Social Quests to reveal the multiplier and maximize your potential rewards. Unlock hidden rewards by adding liquidity and earning more LP points. Increase your TVL, complete various tasks, and earn exclusive bonuses. The faster you participate, the higher your multiplier of points and receiving high rewards.
                            </div>
                        </ScobedText>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default LiquidityQuestPage
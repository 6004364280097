import axios from "axios"


export async function getDiscordAuthLink(token: string){
    const resp = await axios.get("https://blinkswap.xyz/api/v1/follow-discord", {
        headers:{
            'Authorization': `token ${token}`
        }
    })

    return resp.data.data.auth_link as string
}

export async function sendDiscordCode(code: string, token: string){
    const resp = await axios.post("https://blinkswap.xyz/api/v1/discord-response",
        {
            code: code
        },
        {
            headers:{
                'Authorization': `token ${token}`
            }
        }
    )
}

export async function verifyRole(token: string){
    const resp = await axios.get("https://blinkswap.xyz/api/v1/discord-task", {
        headers:{
            'Authorization': `token ${token}`
        }
    })
}
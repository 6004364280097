import { switchChain } from "../app/ethereumFunctions"
import DefaultButton from "./DefaultButton"

function SwitchChainRequire(){
    function handleSwitchChain(){
        switchChain()
    }

    return(
        <div className="w-full flex flex-col grow items-center justify-center">
            <div className="z-30 text-grey mb-3">
                To use app switch chain in your wallet
            </div>
            <div className="z-30" onClick={handleSwitchChain}>
                <DefaultButton className="px-5 py-2 text-sm">
                    Switch chain
                </DefaultButton>
            </div>
        </div>
    )
}

export default SwitchChainRequire
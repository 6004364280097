import axios from "axios";

export async function getMetrcis(token: string){
    const resp = await axios.get("https://blinkswap.xyz/api/v1/tvl",
        {
            headers: {
                'Authorization': `token ${token}`
            }
        }
    )

    console.log(resp.data)

    return {
        tvl: resp.data.data.tvl as string,
        boost: resp.data.data.boost as string
    }
}
import socialQuestBg from "../../assets/img/social_quest_bg.svg"
import questionMark from "../../assets/img/question_mark.svg"
import copyButton from "../../assets/img/copy_button.svg"
import spotlight from "../../assets/img/spotlight.png"
import DefaultButton from "../../shared/DefaultButton";
import { Link } from "react-router-dom";
import AuthPage from "../../shared/AuthPage";
import { useContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import ConnectRequirePage from "../../shared/ConnectRequirePage";
import { getUserInfo } from "../../app/api/infoApi";
import { UserTask } from "../../interface/interface";
import { AppContext } from "../../app/app.context";
import OnlyDesktop from "../../shared/OnlyDesktop";
import { findAuthToken } from "../../app/utils/findAuthToken";
import { toast } from "react-toastify";

const styles = {
    cardName: "flex justify-center p-6 grow border border-white uppercase font-variable font-medium text-[330%] text-white"
}

function QuestsPage() {
    const{ address, isConnected, chainId } = useAccount()

    const[refCode, setRefCode] = useState<string | undefined>()

    const context = useContext(AppContext)

    function handleCopy(){
        navigator.clipboard.writeText(`https://app.blinkswap.xyz/?ref=${refCode}`)
        toast("Сopied to clipboard!")
    }

    useEffect(()=>{
        if(context.authToken !== undefined && context.authToken !== null){
            getUserInfo(context.authToken!)
            .then((userInfo: UserTask)=>{
                setRefCode(userInfo.refCode)
            })
        }
    }, [context.authToken])

    if(!isConnected){
        return <ConnectRequirePage/>
    }

    if(!context.authToken){
        return(
            <AuthPage/>
        )
    }

    if(context.width < 1000){
        return(
            <OnlyDesktop/>
        )
    }

    return (
        <div className="w-full flex grow justify-center">
            <div className="w-[87%] flex flex-col items-center mt-[4%]">
                <div className="relative w-full">
                    <img src={spotlight} alt="" className="w-full absolute translate-x-[80%] translate-y-[-32%]"/>
                </div>
                <div className="w-full flex gap-8 z-20">
                    <div className="w-full flex flex-col grow aspect-[0.95/1] overflow-hidden bg-[url(/src/assets/img/social_quest_bg.svg)] bg-cover">
                        <div className="flex justify-center p-6 grow border border-white uppercase font-variable font-medium text-2xl 2xl:text-4xl text-white border-b-0">
                            Social Q
                        </div>
                        <Link to="/quests/social">
                            <DefaultButton className="[font-size:_clamp(18px,1vw,42px)]">
                                Join
                            </DefaultButton>
                        </Link>
                    </div>
                    <div className="w-full flex flex-col grow aspect-square overflow-hidden bg-[url(/src/assets/img/swap_quest_bg.svg)] bg-cover">
                        <div className="flex justify-center p-6 grow border border-white uppercase font-variable font-medium text-2xl 2xl:text-4xl text-white border-b-0">
                            Swap Q
                        </div>
                        <Link to="/swap">
                            <DefaultButton className="[font-size:_clamp(18px,1vw,42px)]">
                                Swap
                            </DefaultButton>
                        </Link>
                    </div>
                    <div className="w-full flex flex-col grow aspect-square overflow-hidden bg-[url(/src/assets/img/lp_quest_bg.svg)] bg-cover">
                        <div className="flex justify-center p-6 grow border border-white uppercase font-variable font-medium text-2xl 2xl:text-4xl text-white border-b-0">
                            Lp Q
                        </div>
                        <Link to="/quests/liquidity">
                            <DefaultButton className="[font-size:_clamp(18px,1vw,42px)]">
                                Add
                            </DefaultButton>
                        </Link>
                    </div>
                </div>
                <div className="w-full flex flex-col my-auto gap-[1vh] z-20 ">
                    <div className="flex gap-2 items-center">
                        <div className="text-grey text-sm 2xl:text-base font-variable font-light">
                            Invite people to get 5% of Blink Points, LP Points, Swap Points
                        </div>
                        <div>
                            <img src={questionMark} alt="" className="size-4"/>
                        </div>
                    </div>
                    <div className="px-4 py-1 border border-white w-fit text-white">
                        {refCode?
                            <div className="flex gap-2 items-center">
                                <div className="text-white text-sm 2xl:text-base">
                                    {`app.blinkswap.xyz/?ref=${refCode}`}
                                </div>
                                <div onClick={handleCopy}>
                                    <img src={copyButton} alt="" className="size-4 cursor-pointer"/>
                                </div>
                            </div>
                            :
                            <div>To get referral link complete all <span className="hover:text-grey duration-150 underline"><Link to="/quests/social">social quests</Link></span></div>
                        }
                    </div>
                </div>
                <div className="relative w-full">
                    <img src={spotlight} alt="" className="w-full absolute translate-y-[-25%]"/>
                </div>
            </div>
        </div>
    );
}

export default QuestsPage;
import { useAccount } from "wagmi"
import { BLAST_SCAN_URL } from "../../app/constants"
import { cn } from "../../app/utils/cn"

interface UserPosition{
    address: string,
    points: string
}

interface PositionDrawerProps{
    positions: UserPosition[]
}

function formatPoints(points: string){
    

    if(Number(points) <= 0.001){
        return "< 0.001"
    }
    if(Number(points) === 0){
        return "0"
    }
    else{
        return Number(points).toFixed(3)
    }
}

function PositionDrawer({positions}: PositionDrawerProps){
    const {address} = useAccount()

    return(
        <div className="flex flex-col gap-[1px]">
            <div className="flex gap-[1px] uppercase h-12 text-sm 2xl:text-base">
                <div className="text-grey outline-1 outline-darkGrey outline w-full flex justify-center items-center">
                    Address
                </div>
                <div className="text-grey outline-1 outline-darkGrey outline w-full flex justify-center items-center">
                    Points
                </div>
            </div>
            {positions.map((position)=>{
                return(
                    <div className={cn("flex gap-[1px] h-12 text-white text-sm 2xl:text-base", {"border-white border": position.address === address?.toString()})}>
                        <a className={cn("outline-1 outline-darkGrey outline w-full flex justify-center items-center duration-200 hover:underline hover:text-grey", {"outline-white": position.address === address?.toString()})} href={BLAST_SCAN_URL + "/address/" + position.address} target="_blank">
                            {position.address.slice(0, 4) + "..." + position.address.slice(38, 42)}
                        </a>
                        <div className={cn("outline-1 outline-darkGrey outline w-full flex justify-center items-center", {"outline-white": position.address === address?.toString()})}>
                            {formatPoints(position.points)}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default PositionDrawer